// import React, { useState, useEffect } from "react";
import SVG from "react-inlinesvg";
import { ButtonGroup, Button } from "react-bootstrap";
import { sortCaret, toAbsoluteUrl } from "../../_metronic/_helpers";
import { statusClass } from "./UIhelpers";
import { MDBDataTableV5 } from "mdbreact";
import { useIntl } from "react-intl";
import { Modal } from "react-bootstrap";
import { StockUpdateDialog } from "../stockupdate/StockUpdateDialog";
// import { formatDateTime } from "./UIhelpers";
// import {
//   retrieveProject,
//   retrieveProduct,
//   updateProductAPI,
// } from "../../app/network/api";
// import axios from "axios";
// import moment from "moment";
// import { useSnackbar } from "notistack";
// import { REACT_APP_API } from "../../config";

// const ProductsTable = () => {
//   const intl = useIntl();
//   const [isEditModal, setIsEditModal] = useState(false);
//   const [ID, setID] = useState("");
//   const [data, setData] = useState([]);
//   const [locap, setLocap] = useState({
//     prodloc: [],
//   });
//   const [InventoryData, setInventoryData] = useState([]);
//   const { enqueueSnackbar } = useSnackbar();

//   const getProduct = (id) => {
//     retrieveProduct(id).then((res) => {
//       setLocap({
//         ...locap,
//         prodloc: res.data,
//       });
//       setID(id);
//       console.log("IDP", ID);
//       setIsEditModal(true);
//       console.log("rep", res.data.inventory_levels);
//     });
//   };

//   const fetchData = () =>
//     axios.get(`${process.env.REACT_APP_API}admin/products/`);
//   const ff = async () => {
//     var dd = await fetchData();
//     console.log("dd data", dd.data.data);
//     setData(dd.data.data);
//   };
//   useEffect(() => {
//     ff();
//   }, []);


//   const fetchInventory = (id) => axios.get(`${REACT_APP_API}products/${id}`);
//   const fetchLocations = () => axios.get(`${REACT_APP_API}locations`);
//   const getInventory = async (id) => {
//     var dd = await fetchInventory(id);
//     console.log(dd.data.inventory_levels);
//     // setInventoryData(dd.data.inventory_levels);
//     var { data: locations } = await fetchLocations();
//     setIsEditModal(true);
//     const array = dd.data.inventory_levels.map((obj) => {
//       for (let i = 0; i < locations.locations.length; i++) {
//         if (locations.locations[i].id === obj.location_id) {
//           return { ...obj, ...{ location_name: locations.locations[i].name } };
//         }
//       }
//     });
//     setInventoryData(array);
//     setID(id);

//   };

//   const updateProduct = async (obj) => {
//     console.log(obj);
//     await axios.post(`${REACT_APP_API}products/${ID}`, obj);
//     ff();
//     enqueueSnackbar("商品が作成されました。", { variant: "success" });
//     setIsEditModal(false);
//   };

//   const data1 = {
//     columns: [
//       {
//         label: intl.formatMessage({ id: "PRODUCTNAME" }),
//         field: "product_name",
//         sort: "asc",
//         // width: 200
//       },
//       {
//         label: intl.formatMessage({ id: "PRICE" }),
//         field: "price",
//         sort: "asc",
//         // width: 200
//       },
//       {
//         label: intl.formatMessage({ id: "QUANTITY" }),
//         field: "qty",
//         sort: "asc",
//         // width: 200
//       },
//       {
//         label: intl.formatMessage({ id: "PRODUCTADDED" }),
//         field: "created_at",
//         sort: "asc",
//         formatter: (data) => (data ? formatDateTime(data) : "not yet"),
//         // width: 200
//       },
//       {
//         label: intl.formatMessage({ id: "SELLERNAME" }),
//         field: "seller_id",
//         sort: "asc",
//         // width: 200
//       },
//       // {
//       //   label: intl.formatMessage({ id: "STATUS" }),
//       //   field: "status",
//       //   sort: "asc",
//       //   // width: 200
//       // },

//       {
//         label: intl.formatMessage({ id: "ACTION" }),
//         field: "_id",
//       },
//     ],
//     rows:
//       // [
//       data.map((item, index) => {
//         // {console.log(item)}
//         return {
//           product_name: `${item.name}`,
//           price: `${item.price}`,
//           qty: `${item.stock_quantity}`,
//           created_at: moment(`${item.created_at}`).format("YYYY/MM/DD"),
//           seller_id: `${item.seller_id.username}`,
//           _id: (
//             <button
//               className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
//               onClick={() => getInventory(`${item._id}`)}
//             // onClick={() => getProduct(`${item._id}`)}
//             >
//               <span className="symbol-label">
//                 <SVG
//                   className="svg-icon menu-icon"
//                   style={{ fill: "blue", width: "17px" }}
//                   src={toAbsoluteUrl("/media/svg/icons/Design/Edit.svg")}
//                 />
//               </span>
//             </button>
//           ),
//         };
//       }),
//   };

//   return (
//     <React.Fragment>
//       <StockUpdateDialog
//         state={InventoryData}
//         // state={locap.prodloc}
//         updateProduct={updateProduct}
//         id={ID}
//         setState={setLocap}
//         show={isEditModal}
//         onHide={() => setIsEditModal(false)}
//       />
//         <MDBDataTableV5 hover entriesOptions={[10, 20, 25]} entries={10} pagesAmount={20} data={data1}  proSelect searchTop searchBottom={false} fullPagination />
   
//       {/* <MDBDataTableV5
//         hover
       
//         entries={10}
//         pagesAmount={10}
//         data={data1}
//         searchTop
//         searchBottom={false}
//         fullPagination

//       /> */}
//     </React.Fragment>
//   );
// };

// export default ProductsTable;



import React, { useEffect, useState } from "react";
import { Card, CardBody, CardTitle } from "reactstrap";
import {
  useTable,
  usePagination,
  useSortBy,
  useFilters,
  useGlobalFilter,
  useAsyncDebounce,
} from "react-table";
import classnames from "classnames";

// A great library for fuzzy filtering/sorting items
import { matchSorter } from "match-sorter";

import DatatablePagination from "./DatatablePagination";

// Define a default UI for filtering
function GlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
}) {
  const count = preGlobalFilteredRows.length;
  const [value, setValue] = React.useState(globalFilter);
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  return (
    <span>
      検索: {" "}
      <input
        value={value || ""}
        onChange={(e) => {
          e.preventDefault();
          setValue(e.target.value);
          onChange(e.target.value);
        }}
        placeholder={`${count} レコード ...`}
        style={{
          fontSize: "1.1rem",
          border: "0",
          position: "block",
        }}
      />
    </span>
  );
}

// Define a default UI for filtering
function DefaultColumnFilter({
  column: { filterValue, preFilteredRows, setFilter },
}) {
  const count = preFilteredRows.length;

  return (
    <input
      value={filterValue || ""}
      onChange={(e) => {
        setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
      }}
      placeholder={`Search ${count} records...`}
    />
  );
}

export default function ProductsTable({
  columns,
  data = [],
  divided = false,
  defaultPageSize = 6,
}) {
  function fuzzyTextFilterFn(rows, id, filterValue) {
    return matchSorter(rows, filterValue, { keys: [(row) => row.values[id]] });
  }

  // Let the table remove the filter if the string is empty
  fuzzyTextFilterFn.autoRemove = (val) => !val;

  const filterTypes = React.useMemo(
    () => ({
      // Add a new fuzzyTextFilterFn filter type.
      fuzzyText: fuzzyTextFilterFn,
      // Or, override the default text filter to use
      // "startWith"
      text: (rows, id, filterValue) => {
        return rows.filter((row) => {
          const rowValue = row.values[id];
          return rowValue !== undefined
            ? String(rowValue)
                .toLowerCase()
                .startsWith(String(filterValue).toLowerCase())
            : true;
        });
      },
    }),
    []
  );

  const defaultColumn = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
    }),
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    prepareRow,
    headerGroups,
    page,
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    setPageSize,
    state,
    visibleColumns,
    preGlobalFilteredRows,
    setGlobalFilter,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      defaultColumn, // Be sure to pass the defaultColumn option
      filterTypes,
      initialState: { pageIndex: 0, pageSize: defaultPageSize },
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  console.log(page);

 const handleScroll=(event) => {
    let headers = document.getElementsByClassName("rt-thead");
    for (let i = 0; i < headers.length; i++) {
      headers[i].scrollLeft = event.target.scrollLeft;
    }
  }
 


  return (
    <React.Fragment>
      <table
      
        {...getTableProps()}
        className={`r-table table rt-thead ${classnames({ "table-divided": divided })}`}
      >
        <thead>
          <tr>
            <th
              colSpan={visibleColumns.length}
              style={{
                textAlign: "left",
              }}
            >
              <GlobalFilter
                preGlobalFilteredRows={preGlobalFilteredRows}
                globalFilter={state.globalFilter}
                setGlobalFilter={setGlobalFilter}
              />
            </th>
          </tr>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column, columnIndex) => (
                <th
                  key={`th_${columnIndex}`}
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  // className={
                  //   column.isSorted
                  //     ? column.isSortedDesc
                  //       ? "sorted-desc"
                  //       : "sorted-asc"
                  //     : ""
                  // }
                >
                  {column.render("Header")}
                  {/* Add a sort direction indicator */}
                  <span>
                    {column.isSorted
                      ? column.isSortedDesc
                        ? " 🔽"
                        : " 🔼"
                      : ""}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>

        <tbody {...getTableBodyProps()}>
          {page.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell, cellIndex) => (
                  <td
                    key={`td_${cellIndex}`}
                    {...cell.getCellProps({
                      className: cell.column.cellClass,
                    })}
                  >
                    {cell.render("Cell")}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>

      <DatatablePagination
        page={pageIndex}
        pages={pageCount}
        canPrevious={canPreviousPage}
        canNext={canNextPage}
        pageSizeOptions={[4, 10, 20, 30, 40, 50]}
        showPageSizeOptions={true}
        showPageJump={true}
        defaultPageSize={pageSize}
        onPageChange={(p) => gotoPage(p)}
        onPageSizeChange={(s) => setPageSize(s)}
        paginationMaxSize={pageCount}
      />
    </React.Fragment>
  );
}