// /* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
// import React, { useMemo, useEffect } from "react";
// import objectPath from "object-path";
// import ApexCharts from "apexcharts";
// import { useQuery } from "react-query";
// import SVG from "react-inlinesvg";
// import { toAbsoluteUrl } from "../../../_helpers";
// import { useHtmlClassService } from "../../../layout";
// import { query } from "../../../../app/network";
// import { useIntl } from "react-intl";

// export function Card1({ className, symbolShape, baseColor }) {
//   const uiService = useHtmlClassService();
//   const intl = useIntl();
//   const { data, isLoading, isError } = useQuery("products", query.getProducts);
//   useEffect(() => {

//     console.log("check count",data?.data?.data.length);
//   }, [data])
//   const layoutProps = useMemo(() => {
//     return {
//       colorsGrayGray500: objectPath.get(
//         uiService.config,
//         "js.colors.gray.gray500"
//       ),
//       colorsGrayGray200: objectPath.get(
//         uiService.config,
//         "js.colors.gray.gray200"
//       ),
//       colorsGrayGray300: objectPath.get(
//         uiService.config,
//         "js.colors.gray.gray300"
//       ),
//       colorsThemeBaseSuccess: objectPath.get(
//         uiService.config,
//         `js.colors.theme.base.${baseColor}`
//       ),
//       colorsThemeLightSuccess: objectPath.get(
//         uiService.config,
//         `js.colors.theme.light.${baseColor}`
//       ),
//       fontFamily: objectPath.get(uiService.config, "js.fontFamily"),
//     };
//   }, [uiService, baseColor]);

//   useEffect(() => {
//     const element = document.getElementById("kt_stats_widget_1_chart");

//     if (!element) {
//       return;
//     }

//     const options = getChartOption(layoutProps);
//     const chart = new ApexCharts(element, options);
//     chart.render();
//     return function cleanUp() {
//       chart.destroy();
//     };
//   }, [layoutProps]);

//   return (
//     <div className={`card card-custom ${className}`}>
//       <div className="card-body p-0">
//         <div className="d-flex align-items-center justify-content-between card-spacer flex-grow-1">
//           <span
//             className={`symbol ${symbolShape} symbol-50 symbol-light-${baseColor} mr-2`}
//           >
//             <span className="symbol-label">
//               <span className={`svg-icon svg-icon-xl svg-icon-${baseColor}`}>
//                 <SVG
//                   src={toAbsoluteUrl(
//                     "/media/svg/icons/Layout/Layout-4-blocks.svg"
//                   )}
//                 ></SVG>
//               </span>
//             </span>
//           </span>

//         </div>

//         <div style={{marginLeft:"130px", marginBottom:"45px"}}className="d-flex flex-column text">
//             <h1 style={{fontSize:"60px"}} className="text-dark-75 font-weight-bolder">
//               {data?.data?.data.length}
//             </h1>
//             <span style={{fontSize:"15px"}} className="text-muted font-weight-bold mt-2">
//             {intl.formatMessage({ id: "TOTALPRODUCTS" })}
//             </span>
//           </div>


//         {/* <div
//           id="kt_stats_widget_11_chart"
//           className="card-rounded-bottom"
//           style={{ height: "150px" }}
//         ></div> */}
//       </div>
//     </div>
//   );
// }

// function getChartOption(layoutProps) {
//   const options = {
//     series: [
//       {
//         name: "Net Profit",
//         data: [40, 40, 30, 30, 35, 35, 50],
//       },
//     ],
//     chart: {
//       type: "area",
//       height: 150,
//       toolbar: {
//         show: false,
//       },
//       zoom: {
//         enabled: false,
//       },
//       sparkline: {
//         enabled: true,
//       },
//     },
//     plotOptions: {},
//     legend: {
//       show: false,
//     },
//     dataLabels: {
//       enabled: false,
//     },
//     fill: {
//       type: "solid",
//       opacity: 1,
//     },
//     stroke: {
//       curve: "smooth",
//       show: true,
//       width: 3,
//       colors: [layoutProps.colorsThemeBaseSuccess],
//     },
//     xaxis: {
//       categories: ["Feb", "Mar", "Apr", "May", "Jun", "Aug", "Sep"],
//       axisBorder: {
//         show: false,
//       },
//       axisTicks: {
//         show: false,
//       },
//       labels: {
//         show: false,
//         style: {
//           colors: layoutProps.colorsGrayGray500,
//           fontSize: "12px",
//           fontFamily: layoutProps.fontFamily,
//         },
//       },
//       crosshairs: {
//         show: false,
//         position: "front",
//         stroke: {
//           color: layoutProps.colorsGrayGray300,
//           width: 1,
//           dashArray: 3,
//         },
//       },
//       tooltip: {
//         enabled: true,
//         formatter: undefined,
//         offsetY: 0,
//         style: {
//           fontSize: "12px",
//           fontFamily: layoutProps.fontFamily,
//         },
//       },
//     },
//     yaxis: {
//       min: 0,
//       max: 55,
//       labels: {
//         show: false,
//         style: {
//           colors: layoutProps.colorsGrayGray500,
//           fontSize: "12px",
//           fontFamily: layoutProps.fontFamily,
//         },
//       },
//     },
//     states: {
//       normal: {
//         filter: {
//           type: "none",
//           value: 0,
//         },
//       },
//       hover: {
//         filter: {
//           type: "none",
//           value: 0,
//         },
//       },
//       active: {
//         allowMultipleDataPointsSelection: false,
//         filter: {
//           type: "none",
//           value: 0,
//         },
//       },
//     },
//     tooltip: {
//       style: {
//         fontSize: "12px",
//         fontFamily: layoutProps.fontFamily,
//       },
//       y: {
//         formatter: function(val) {
//           return "$" + val + " thousands";
//         },
//       },
//     },
//     colors: [layoutProps.colorsThemeLightSuccess],
//     markers: {
//       colors: [layoutProps.colorsThemeLightSuccess],
//       strokeColor: [layoutProps.colorsThemeBaseSuccess],
//       strokeWidth: 3,
//     },
//   };
//   return options;
// }

/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useMemo, useEffect } from "react";
import objectPath from "object-path";
import ApexCharts from "apexcharts";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../_helpers";
import { useHtmlClassService } from "../../../layout";
import { query } from "../../../../app/network";
import { useQuery } from "react-query";
import { useIntl } from "react-intl";

export function Card1({ className, symbolShape, baseColor }) {
  const uiService = useHtmlClassService();
  const intl = useIntl();
  const { data, isLoading, isError } = useQuery("products", query.getProducts);
  useEffect(() => {
    console.log("order", data?.data?.data)

    console.log("check count products", data?.data?.data);
  }, [data])
  const layoutProps = useMemo(() => {
    return {
      colorsGrayGray500: objectPath.get(
        uiService.config,
        "js.colors.gray.gray500"
      ),
      colorsGrayGray200: objectPath.get(
        uiService.config,
        "js.colors.gray.gray200"
      ),
      colorsGrayGray300: objectPath.get(
        uiService.config,
        "js.colors.gray.gray300"
      ),
      colorsThemeBaseSuccess: objectPath.get(
        uiService.config,
        `js.colors.theme.base.${baseColor}`
      ),
      colorsThemeLightSuccess: objectPath.get(
        uiService.config,
        `js.colors.theme.light.${baseColor}`
      ),
      fontFamily: objectPath.get(uiService.config, "js.fontFamily"),
    };
  }, [uiService, baseColor]);

  useEffect(() => {
    const element = document.getElementById("kt_stats_widget_1_chart");

    if (!element) {
      return;
    }

    const options = getChartOption(layoutProps);
    const chart = new ApexCharts(element, options);
    chart.render();
    return function cleanUp() {
      chart.destroy();
    };
  }, [layoutProps]);

  return (
    <div className={`card card-custom ${className}`}>
      <div className="card-body p-0">
        <div className="d-flex align-items-center justify-content-between card-spacer flex-grow-1">
          <span
            className={`symbol ${symbolShape} symbol-50 symbol-light-${baseColor} mr-2`}
          >
            <span className="symbol-label">
              <span className={`svg-icon svg-icon-xl svg-icon-${baseColor}`}>
                <SVG
                  src={toAbsoluteUrl(
                    "/media/svg/icons/Layout/Layout-4-blocks.svg"
                  )}
                ></SVG>
              </span>
            </span>
          </span>

        </div>

        {/* <div style={{marginLeft:"130px", marginBottom:"45px"}}className="d-flex flex-column text">
            <h1 style={{fontSize:"64px"}} className="text-dark-75  font-weight-bolder">
            {data?.data?.data.length}
         
            </h1>
            <span style={{fontSize:"15px"}} className="text-muted font-weight-bold mt-2">
            {intl.formatMessage({ id: "TOTALPRODUCTS" })}
            </span>
          </div> */}

        <div
          style={{
            textAlign: "center",
            marginBottom: "35px",
            // marginTop: "35px",
          }}
          className="d-flex flex-column text"
        >
          <h1
            style={{ fontSize: "81px" }}
            className="text-dark-75 font-weight-bolder"
          >
            {data?.data?.data.length}
          </h1>
          <span
            style={{ fontSize: "20px" }}
            className="text-muted font-weight-bold mt-2"
          >
            {/* {intl.formatMessage({ id: "TOTALSELLERS" })} */}
            {intl.formatMessage({ id: "TOTALPRODUCTS" })}
          </span>
        </div>


        {/* <div
          id="kt_stats_widget_11_chart"
          className="card-rounded-bottom"
          style={{ height: "150px" }}
        ></div> */}
      </div>
    </div>
  );
}

function getChartOption(layoutProps) {
  const options = {
    series: [
      {
        name: "Net Profit",
        data: [40, 40, 30, 30, 35, 35, 50],
      },
    ],
    chart: {
      type: "area",
      height: 150,
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
      sparkline: {
        enabled: true,
      },
    },
    plotOptions: {},
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    fill: {
      type: "solid",
      opacity: 1,
    },
    stroke: {
      curve: "smooth",
      show: true,
      width: 3,
      colors: [layoutProps.colorsThemeBaseSuccess],
    },
    xaxis: {
      categories: ["Feb", "Mar", "Apr", "May", "Jun", "Aug", "Sep"],
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        show: false,
        style: {
          colors: layoutProps.colorsGrayGray500,
          fontSize: "12px",
          fontFamily: layoutProps.fontFamily,
        },
      },
      crosshairs: {
        show: false,
        position: "front",
        stroke: {
          color: layoutProps.colorsGrayGray300,
          width: 1,
          dashArray: 3,
        },
      },
      tooltip: {
        enabled: true,
        formatter: undefined,
        offsetY: 0,
        style: {
          fontSize: "12px",
          fontFamily: layoutProps.fontFamily,
        },
      },
    },
    yaxis: {
      min: 0,
      max: 55,
      labels: {
        show: false,
        style: {
          colors: layoutProps.colorsGrayGray500,
          fontSize: "12px",
          fontFamily: layoutProps.fontFamily,
        },
      },
    },
    states: {
      normal: {
        filter: {
          type: "none",
          value: 0,
        },
      },
      hover: {
        filter: {
          type: "none",
          value: 0,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: "none",
          value: 0,
        },
      },
    },
    tooltip: {
      style: {
        fontSize: "12px",
        fontFamily: layoutProps.fontFamily,
      },
      y: {
        formatter: function (val) {
          return "$" + val + " thousands";
        },
      },
    },
    colors: [layoutProps.colorsThemeLightSuccess],
    markers: {
      colors: [layoutProps.colorsThemeLightSuccess],
      strokeColor: [layoutProps.colorsThemeBaseSuccess],
      strokeWidth: 3,
    },
  };
  return options;
}
