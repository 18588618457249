  
import ResetPassword from "./ResetPassword";

export default function ResetPasswordLink({ match }) {
  const urlParams = new URLSearchParams(window.location.search);

  //   const code = urlParams.get("code");
  //   const username = urlParams.get("user_name");
  const resetLink = match.params.code;

  return <ResetPassword resetLink={resetLink} />;
}