import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { FormattedMessage, injectIntl } from "react-intl";
import { useHistory } from "react-router-dom";
import "../../../_metronic/_assets/sass/pages/login/classic/login-1.scss";
import { createseller } from "../../network/api";
import { useSnackbar } from "notistack";
import './Style4.css'
import {Field } from 'formik';




function CreateSeller(props) {
    const history = useHistory();
    const { enqueueSnackbar } = useSnackbar();
    const { intl } = props;
    const [loading, setLoading] = useState(false);
    var generator = require('generate-password');

    var passwords = generator.generate({
        length: 10,
        uppercase: false,
        numbers: true,
        exclude: "abcdefghijklmnopqrstuvwxyz"
    });
    console.log("password:", passwords);
    const backToSellersList = () => {
        history.push("/sellers");
    };
    const [state, setState] = useState({
        errorMessage: "",
    });


    const initialValues = {
        username: "",
        email: "",
        sellerKatakana:"",
        password: passwords,
    };
   
      

      

      Yup.addMethod(Yup.string, "katakanaType", function (errorMessage) {
        return this.test(`test-card-length`, errorMessage, function (str) {
            let error;
            // if (str && /[ァ-ヴー]+/u.test(str)) {
            if (str &&  /^([\u3000-\u303f\u30a0-\u30ff\uff00-\uff9f\s\-\(\)\.]+)$/.test(str)) {
                error="check"
                return error
                // return rStr.replace(/[\uff01-\uff5e]/g, (ch) =>
                //   String.fromCharCode(ch.charCodeAt(0) - 0xfee0)
                // );
              }
              return error;
           
          });
    
      });
      
    const LoginSchema = Yup.object().shape({
        username: Yup.string()
            // .test('test-name', 'Validation failure message', 
            //     validateUsername()
            //     )

            .required("ユーザー名は必須項目です。")

        ,
        sellerKatakana: Yup.string()
            // .test('test-name', 'Validation failure message', 
            //     validateUsername()
            //     )
            .katakanaType("'フリガナ' はカタカナで入力してください。")
            .required("ユーザー名は必須項目です。")

        ,
        email: Yup.string()
            .min(3, "Minimum 3 symbols")
            .max(50, "Maximum 50 symbols")
            .required("メールアドレスは必須項目です")

        ,
        password: Yup.string()
            .min(3, "Minimum 3 symbols")
            .max(50, "Maximum 50 symbols")
            .required("パスワードは必須項目です。")
        ,
    });

    const enableLoading = () => {
        setLoading(true);
    };

    const disableLoading = () => {
        setLoading(false);
    };

    const getInputClasses = (fieldname) => {
        if (formik.touched[fieldname] && formik.errors[fieldname]) {
            return "is-invalid";
        }

        if (formik.touched[fieldname] && !formik.errors[fieldname]) {
            return "is-valid";
        }

        return "";
    };

    const formik = useFormik({
        initialValues,
        validationSchema: LoginSchema,
        onSubmit: (values, { setStatus, setSubmitting }) => {
            enableLoading();
            setTimeout(() => {
                createseller({ username: values.username,  sellerKatakana: values.sellerKatakana, email: values.email, password: values.password })
                    .then(({ data: { message } }) => {
                        enqueueSnackbar("新しい生産者が作成されました", { variant: "success" });
                        disableLoading();
                        setSubmitting(false);
                        backToSellersList();
                        // props.login(accessToken);

                    })
                    .catch((values) => {
                        enqueueSnackbar("このメールアドレスはすでに登録されています", { variant: "error" });
                        console.log("message",values.message);
                    })
                    // .catch(() => {
                    //     setStatus("Please fill in the Details"
                    //     );
                    //     enqueueSnackbar(values.message, { variant: "danger" }); 
                    // })
                    .finally(() => {
                        disableLoading();
                        setSubmitting(false);
                        
                    });
            }, 1000);
        },
    });
  


    return (
        <div className="login-form login-signin d-flex flex-column flex-root bg-white" style={{ padding: "30px" }} >

            <div className=" text-center mb-10 mb-lg-20" >
                <h3 className="font-size-h1">
                    <FormattedMessage id="CREATESELLER" />
                </h3>

            </div>

            <div id="createseller" className="lengthy" >

                <form
                    onSubmit={formik.handleSubmit}
                    className="form fv-plugins-bootstrap fv-plugins-framework"
                >
                    {formik.status && (
                        <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                            <div className="alert-text font-weight-bold">{formik.status}</div>
                        </div>
                    )}
                      <div className="form-group fv-plugins-icon-container lengthx">
                        <input
                            placeholder="ユーザーネームを入力"
                            type="text"
                            // style={{ width: "400px" }}
                            className={`form-control form-control-solid h-auto py-5 px-6  ${getInputClasses(
                                "username"
                            )}`}
                            name="username"
                            {...formik.getFieldProps("username")}
                        />
                          {/* <Field name="username" validate={validateUsername}
                          {...formik.getFieldProps("username")} /> */}
       
 
                        {formik.touched.username && formik.errors.username ? (
                            <div className="fv-plugins-message-container">
                                <div className="fv-help-block">{formik.errors.username}</div>
                            </div>
                        ) : null}
                    </div>
                    <div className="form-group fv-plugins-icon-container lengthx">
                        <input
                            placeholder="生産者名(カナ)を入力"
                            type="text"
                            // style={{ width: "400px" }}
                            className={`form-control form-control-solid h-auto py-5 px-6  ${getInputClasses(
                                "sellerKatakana"
                            )}`}
                            name="sellerKatakana"
                            {...formik.getFieldProps("sellerKatakana")}
                        />
                          {/* <Field name="username" validate={validateUsername}
                          {...formik.getFieldProps("username")} /> */}
       
 
                        {formik.touched.sellerKatakana && formik.errors.sellerKatakana ? (
                            <div className="fv-plugins-message-container">
                                <div className="fv-help-block">{formik.errors.sellerKatakana}</div>
                            </div>
                        ) : null}
                    </div>

                    <div className="form-group fv-plugins-icon-container lengthx">
                        <input
                            placeholder="メールアドレスを入力"
                            type="text"
                            // style={{ width: "400px" }}
                            className={`form-control  form-control-solid h-auto py-5 px-6  ${getInputClasses(
                                "email"
                            )}`}
                            name="email"
                            {...formik.getFieldProps("email")}
                        />
                        {formik.touched.email && formik.errors.email ? (
                            <div className="fv-plugins-message-container">
                                <div className="fv-help-block">{formik.errors.email}</div>
                            </div>
                        ) : null}
                    </div>
                    {/* <div className="form-group fv-plugins-icon-container">
                        <input
                            placeholder="パスワードを入力"
                            type="password"
                            style={{ width: "400px" }}
                            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                                "password"
                            )}`}
                            name="password"
                            {...formik.getFieldProps("password")}
                        />
                        {formik.touched.password && formik.errors.password ? (
                            <div className="fv-plugins-message-container">
                                <div className="fv-help-block">{formik.errors.password}</div>
                            </div>
                        ) : null}
                    </div> */}
                    <div className="form-group d-flex flex-wrap  align-items-center">
                        {/* <Link
            to="/auth/forgot-password"
            className="text-dark-50 text-hover-primary my-3 mr-2"
            id="kt_login_forgot"
          >
            <FormattedMessage id="AUTH.GENERAL.FORGOT_BUTTON" />
          </Link> */}
                        <button
                            id="kt_login_signin_submit"
                            type="submit"
                            disabled={formik.isSubmitting}
                            className={`btn btn-primary font-weight-bold px-9  my-3`}
                        >
                            <span>
                                <FormattedMessage id="AUTH.GENERAL.SUBMIT_BUTTON" />
                            </span>
                            {loading && <span className="ml-3 spinner spinner-white"></span>}
                        </button>
                        <button
                            id=""
                            onClick={backToSellersList}
                            style={{ marginLeft: "10px" }}
                            disabled={formik.isSubmitting}
                            className={`btn btn-secondary font-weight-bold`}
                        >
                            <span>
                                <FormattedMessage id="cancel" />
                            </span>
                            {loading && <span className="ml-3 spinner spinner-white"></span>}
                        </button>

                    </div>
                </form>
            </div>
            {/*end::Form*/}
        </div>
    );
}

export default CreateSeller;

