import React from "react";
import {Card1, } from "../widgets/stats/card1"
import {Card2} from "../widgets/stats/card2"
import {Card3} from "../widgets/stats/card3"
import {Card4} from "../widgets/stats/card4"


export function Demo1Dashboard() {
  return (
    <>
      <div className="row ">
      <div className="col-lg-6 col-xl-6">
          <div style={{ marginTop: "20px" }}  >
            <Card1 className="card-stretch card-stretch-half gutter" />
          </div>
          <div style={{ marginTop: "20px" }}>
            <Card2 className="card-stretch card-stretch-half gutter" />
          </div>
        </div>
        {/* <div className="col-lg-3 col-xl-1"></div> */}

        <div className="col-lg-6 col-xl-6">
          <div style={{ marginTop: "20px" }}>
            <Card3 className="card-stretch card-stretch " />
          </div>
          <div style={{ marginTop: "20px" }}>
            <Card4 className="card-stretch card-stretch " />
          </div>
        {/* <div  className="col-lg-6 col-xl-5">
          <div  style={{marginTop:"10px" }}>
          <Card1 className="card-stretch card-stretch " />
          </div>
          <div style={{marginTop:"30px"}}>
          <Card2 className="card-stretch card-stretch-half gutter" />
          </div>
        </div>
        <div className="col-lg-3 col-xxl-1">
        
        </div>

        <div className="col-lg-6 col-xl-5">
        <div  style={{marginTop:"10px"}}>
          <Card3 className="card-stretch card-stretch " />
          </div>
          <div style={{marginTop:"30px"}}>
          <Card4 className="card-stretch card-stretch-half gutter" />
          </div> */}
        {/* <Card1 className="card-stretch card-stretch-half gutter-b" /> */}
          {/* <StatsWidget11
            className="card-stretch card-stretch-half gutter-b"
            symbolShape="circle"
            baseColor="success"
          />
          <StatsWidget12 className="card-stretch card-stretch-half gutter-b" /> */}
        </div>

        {/* <div className="col-lg-6 col-xxl-4 order-1 order-xxl-1">
        <Card1 className="card-stretch card-stretch-half gutter-b" />
        </div>
        <div className="col-xxl-8 order-2 order-xxl-1">
          <AdvanceTablesWidget2 className="card-stretch gutter-b" />
        </div>
        <div className="col-lg-6 col-xxl-4 order-1 order-xxl-2">
          <ListsWidget3 className="card-stretch gutter-b" />
        </div>
        <div className="col-lg-6 col-xxl-4 order-1 order-xxl-2">
          <ListsWidget4 className="card-stretch gutter-b" />
        </div>
        <div className="col-lg-12 col-xxl-4 order-1 order-xxl-2">
          <ListsWidget8 className="card-stretch gutter-b" />
        </div>
      </div>
      <div className="row">
        <div className="col-lg-4">
          <MixedWidget14 className="card-stretch gutter-b" />
        </div>
        <div className="col-lg-8">
          <AdvanceTablesWidget4 className="card-stretch gutter-b" />
        </div> */}

        
      </div>

    </>
  );
}
