import React, { useState, useEffect, useQuery } from "react";
import { Modal } from "react-bootstrap";
import { useIntl } from "react-intl";
import './Style3.css'

export function DeleteConfirmationDialog({ show, onHide, confirm, ID1 }) {
  const [ownState, setOwnState] = useState([]);

  // const updateFieldChanged = (index) => (e) => {
  //   console.log("index: " + index);
  //   console.log("property name: " + e.target.name);
  //   let newArr = [...ownState]; // copying the old datas array
  //   newArr[index].available = Number(e.target.value); // replace e.target.value with whatever you want to change it to

  //   setOwnState(newArr); // ??
  // };

  useEffect(() => {
    setOwnState(ID1);
    console.log("ID1111111",ID1)
  }, [ID1]);

  const intl = useIntl();

  return (
 
    <Modal
     
      show={show}     
      // style={{ width: "max-content" }}
      onHide={onHide}
      // style={{width:"400px", marginTop:"200px",marginLeft:"600px" }}
      scrollable={true}
      aria-labelledby="contained-modal-title-vcenter"
    >
      <Modal.Header closeButton>
        {/* <Modal.Title id="example-modal-sizes-title-lg">
          {" "}
          {intl.formatMessage({ id: "productdetails" })}
        </Modal.Title> */}
      </Modal.Header>
      <Modal.Body>
        <span> 本当に削除してもよろしいでしょうか？</span>
      </Modal.Body>
      <Modal.Footer>
      <div className="row">
              <button
                type="button"
                onClick={onHide}
                className="btn btn-light btn-elevate"
              >
               いいえ
              </button>

              <button
                type="button"
                className="btn btn-delete btn-primary"
                disabled={!ownState}
                onClick={(e) => 
                  confirm(ownState)}
                style={{ marginLeft: "5px" }}
                
              >
                はい
              </button>
            </div>
      </Modal.Footer>
    </Modal>
   
  );
}