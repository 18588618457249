// import React, { useState, useEffect } from 'react';
// import { MDBDataTableV5 } from 'mdbreact';
// import { statusClass } from "./UIhelpers";
// import moment from 'moment';
// import axios from "axios";
// import SVG from "react-inlinesvg";
// import { useIntl } from "react-intl";
// import { REACT_APP_API } from "../../config";
// import OrderDialog from "../ordermodal/OrderDialog";
// import { sortCaret, toAbsoluteUrl } from "../../_metronic/_helpers";
// import {
//   retrieveProject,
//   retrieveOrders,
//   updateSellerAPI,
// } from "../../app/network/api";
// import { EyeOutlined } from "@ant-design/icons";

// const OrdersTable = () => {


//   const [data, setData] = useState([]);
//   const [order, setOrder] = useState([]);
//   const [productData, setProductData] = useState({ prodsData: [] })
//   const [open, setOpen] = useState(false)
//   const [openCustomer, setOpenCustomer] = useState(false);
//   const [productId, setProductId] = useState();

//   const intl = useIntl();

//   const [ID, setID] = useState("");

//   const onOpenModal = () => setOpen(true)
//   const onCloseModal = () => setOpen(false)

//   const onOpenCustomerModal = () => setOpenCustomer(true)
//   const onCloseCustomerModal = () => setOpenCustomer(false)
//   const [customer, setCustomer] = useState({
//     customerName: "",
//     customerAddress: "",
//   });


//   // const getData = () => {
//   //   handleDataRequest(
//   //     'GET',
//   //     `${process.env.REACT_APP_API}/admin/orders/`
//   //   ).then((res) => {
//   //     setTableData({
//   //       ...tableData,
//   //       data: res.data
//   //     })
//   //   }).catch((err) => {
//   //     console.log(err);
//   //   })
//   // }




//   // useEffect(() => {
//   //   getData()
//   //   // console.log(tableData)
//   // }, [])

//   const fetchData = () =>
//     axios.get(`${process.env.REACT_APP_API}admin/orders/`);

//   const fetchProductById = (productId) =>
//     axios.get(
//       `${process.env.REACT_APP_API}/admin/products/${productId}`
//     );

//   const fetchOrderById = (productId) =>
//     axios.get(
//       `${process.env.REACT_APP_API}/admin/orders/${productId}`
//     );


//   const ff = async () => {
//     var dd = await fetchData();
//     console.log("dd orderdata", dd.data.data);
//     setData(dd.data.data);
//   };
//   useEffect(() => {
//     ff();
//   }, []);

//   const productFetch = async () => {
//     var dd = await fetchProductById();
//     console.log(dd.data.data);
//     return dd.data.data;
//   };

//   const orderFetch = async () => {
//     var dd1 = await fetchOrderById();
//     console.log(dd1.data.data);
//     // return dd1.data.data;
//   };

//   useEffect(() => {
//     const ff = async () => {
//       var dd = await fetchData();
//       console.log(dd.data.data);
//       setData(dd.data.data);
//     };
//     ff();

//     // console.log(data);
//   }, []);
//   const [isEditModal, setIsEditModal] = useState(false);


//   const handleCancel = () => {

//     setIsEditModal(false);

//   };


//   const getProduct = (id) => {
//     setID(id)
//     console.log("OID", ID)
//     retrieveOrders(id).then((res) => {
//       setOrder(res.data);
//       // setID(id);
//       console.log("Orderrrrrrrrr", res.data);
//       setIsEditModal(true);

//     });
//   };




//   const data1 = {
//     columns: [
//       {
//         label: intl.formatMessage({ id: "ORDERID" }),
//         field: 'order_id',
//         sort: 'asc',
//       },
//       {
//         label: intl.formatMessage({ id: "orderquantity" }),
//         field: 'orderQuantity',
//         sort: 'asc',
//       },

//       {
//         label: intl.formatMessage({ id: "orderprice" }),
//         field: 'baseOrderPrice',
//         sort: 'asc',
//         // width: 200
//       },
//       // {
//       //   label: intl.formatMessage({ id: "customername" }),
//       //   field: 'customerName',
//       //   sort: 'asc',
//       //   // width: 270
//       // },
//       {
//         label: intl.formatMessage({ id: "Customer" }),
//         field: 'customer',
//         sort: 'asc',
//         // width: 100
//       },

//       {
//         label: intl.formatMessage({ id: "PRODUCTNAME" }),
//         field: 'product',
//         sort: 'asc',
//         // width: 200
//       },
//       {
//         label: intl.formatMessage({ id: "SELLERNAME" }),
//         field: 'sellername',
//         sort: 'asc',
//         // width: 200
//       },

//       {
//         label: intl.formatMessage({ id: "OrderedAt" }),
//         field: 'orderedAt',
//         sort: 'asc',
//         // width: 100
//       },
//       {
//         label: intl.formatMessage({ id: "STATUS" }),
//         field: 'shipmentStatus',
//         sort: 'asc',
//         // width: 100
//       },
     

//     ],
//     rows:

//       data.map((item, index) => {
//         // {console.log(item)}

//         return {
//           order_id: `${item.order_id}`,
//           orderQuantity: `${item.orderQuantity}`,
//           // customerName: `${item.customerName}`,
//           product: `${item.product.name}`,
//           sellername: `${item.product.seller_id.username}`,
//           baseOrderPrice: `${item.baseOrderPrice}`,
//           orderedAt: moment(`${item.orderedAt}`).format("YYYY/MM/DD"),
//           shipmentStatus: <span
//             style={{ width: "91px", height: "33px" }}
//             // className={`label label-lg label-inline label-light-${
//             //   data === true ? "success" : "danger"
//             // }`}
//             className={`label label-lg label-inline label-${statusClass[item.shipmentStatus]}`}
//           >
//             {/* {intl.formatMessage({id:data})} */}
//             {intl.formatMessage({ id: item.shipmentStatus })}
//             {/* {item.shipmentStatus} */}
//           </span>,
//           customer: (
//             <button
//               className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
//               // onClick={() => orderFetch(`${item.order_id}`)}
//               onClick={(e) => {
//                 setIsEditModal(true);
//                 setCustomer({
//                   customerName:`${item.customerName}`,
//                   customerAddress:
//                   `${item.pinCode}` +
//                   ", " +
//                   `${item.country}` +
//                   ", " +
//                  `${item.prefecture}` +
//                   ", " +
//                  `${item.city}` +
//                   ", " +
//                   `${item.address1}` +
//                   ", " +
//                  `${item.address2}`,
//                 });
//               }}
//             // onClick={() => getProduct(`${item._id}`)}
//             >
//               {/* <span className="symbol-label">
//                 <SVG
//                   className="svg-icon menu-icon"
//                   style={{ fill: "blue", width: "17px" }}
//                   src={toAbsoluteUrl("/media/svg/icons/Design/Edit.svg")}
//                 />
//               </span> */}
//                <EyeOutlined />
//             </button>
//           ),



//         }
//       })

//     // {
//     //   order_id: '001',
//     //   order_name: 'Order 001',
//     //   customer_details: "Ishan Jain",
//     //   date: '2011/04/25',
//     //   status: 'Completed'
//     // },

//   };

//   return (
//     <>
//       <OrderDialog
//         show={isEditModal}
//         onHide={() => setIsEditModal(false)}
//         customer={customer}
//       // isEditModal={isEditModal}
//       // handleCancel={handleCancel}
//       // state={state} 


//       />

//       <MDBDataTableV5 hover entriesOptions={[10, 20, 25]} entries={10} pagesAmount={10} data={data1} filter={data.shipmentStatus} proSelect searchTop searchBottom={false}  />
//     </>
//   );
// }

// export default OrdersTable;





import React, { useEffect, useState } from "react";
import { Card, CardBody, CardTitle } from "reactstrap";
import {
  useTable,
  usePagination,
  useSortBy,
  useFilters,
  useGlobalFilter,
  useAsyncDebounce,
} from "react-table";
import classnames from "classnames";

// A great library for fuzzy filtering/sorting items
import { matchSorter } from "match-sorter";

import DatatablePagination from "./DatatablePagination";

// Define a default UI for filtering
function GlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
}) {
  const count = preGlobalFilteredRows.length;
  const [value, setValue] = React.useState(globalFilter);
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  return (
    <span>
      検索: {" "}
      <input
        value={value || ""}
        onChange={(e) => {
          e.preventDefault();
          setValue(e.target.value);
          onChange(e.target.value);
        }}
        placeholder={`${count} レコード ...`}
        style={{
          fontSize: "1.1rem",
          border: "0",
          position: "block",
        }}
      />
    </span>
  );
}

// Define a default UI for filtering
function DefaultColumnFilter({
  column: { filterValue, preFilteredRows, setFilter },
}) {
  const count = preFilteredRows.length;

  return (
    <input
      value={filterValue || ""}
      onChange={(e) => {
        setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
      }}
      placeholder={`Search ${count} records...`}
    />
  );
}

export default function OrdersTable({
  columns,
  data = [],
  divided = false,
  defaultPageSize = 6,
}) {
  function fuzzyTextFilterFn(rows, id, filterValue) {
    return matchSorter(rows, filterValue, { keys: [(row) => row.values[id]] });
  }

  // Let the table remove the filter if the string is empty
  fuzzyTextFilterFn.autoRemove = (val) => !val;

  const filterTypes = React.useMemo(
    () => ({
      // Add a new fuzzyTextFilterFn filter type.
      fuzzyText: fuzzyTextFilterFn,
      // Or, override the default text filter to use
      // "startWith"
      text: (rows, id, filterValue) => {
        return rows.filter((row) => {
          const rowValue = row.values[id];
          return rowValue !== undefined
            ? String(rowValue)
                .toLowerCase()
                .startsWith(String(filterValue).toLowerCase())
            : true;
        });
      },
    }),
    []
  );

  const defaultColumn = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
    }),
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    prepareRow,
    headerGroups,
    page,
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    setPageSize,
    state,
    visibleColumns,
    preGlobalFilteredRows,
    setGlobalFilter,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      defaultColumn, // Be sure to pass the defaultColumn option
      filterTypes,
      initialState: { pageIndex: 0, pageSize: defaultPageSize },
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  console.log(page);

  return (
    <React.Fragment>
      <table
        {...getTableProps()}
        className={`r-table table ${classnames({ "table-divided": divided })}`}
      >
        <thead>
          <tr>
            <th
              colSpan={visibleColumns.length}
              style={{
                textAlign: "left",
              }}
            >
              <GlobalFilter
                preGlobalFilteredRows={preGlobalFilteredRows}
                globalFilter={state.globalFilter}
                setGlobalFilter={setGlobalFilter}
              />
            </th>
          </tr>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column, columnIndex) => (
                <th
                  key={`th_${columnIndex}`}
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  // className={
                  //   column.isSorted
                  //     ? column.isSortedDesc
                  //       ? "sorted-desc"
                  //       : "sorted-asc"
                  //     : ""
                  // }
                >
                  {column.render("Header")}
                  {/* Add a sort direction indicator */}
                  <span>
                    {column.isSorted
                      ? column.isSortedDesc
                        ? " 🔽"
                        : " 🔼"
                      : ""}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>

        <tbody {...getTableBodyProps()}>
          {page.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell, cellIndex) => (
                  <td
                    key={`td_${cellIndex}`}
                    {...cell.getCellProps({
                      className: cell.column.cellClass,
                    })}
                  >
                    {cell.render("Cell")}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>

      <DatatablePagination
        page={pageIndex}
        pages={pageCount}
        canPrevious={canPreviousPage}
        canNext={canNextPage}
        pageSizeOptions={[4, 10, 20, 30, 40, 50]}
        showPageSizeOptions={true}
        showPageJump={true}
        defaultPageSize={pageSize}
        onPageChange={(p) => gotoPage(p)}
        onPageSizeChange={(s) => setPageSize(s)}
        paginationMaxSize={pageCount}
      />
    </React.Fragment>
  );
}


