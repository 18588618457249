import React, { useState } from "react";
import { useFormik } from "formik";
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import * as Yup from "yup";
import { injectIntl } from "react-intl";
import * as auth from "../_redux/authRedux";
import { resetPassword } from "../_redux/authCrud";
import { FormattedMessage } from "react-intl";
import { useSnackbar } from "notistack";
// import SuccessMessage from "../../../../../src/_metronic/layout/components/SuccessMessage";

const initialValues = {
  newPass: "",
  confirmPass: "",
};

function ResetPassword(props) {
  const { intl } = props;
  const [isRequested, setIsRequested] = useState(false);
  const [success, setSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const { enqueueSnackbar } = useSnackbar();
  const ForgotPasswordSchema = Yup.object({
    newPass: Yup.string().required("パスワードは必須です。"),
    confirmPass: Yup.string().oneOf(
      [Yup.ref("newPass"), null],
      "パスワードが一致していません。"
    ),
  });

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const onClose = () => {
    console.log("clicked");
    setSuccess(false);
  };

  const formik = useFormik({
    initialValues,
    validationSchema: ForgotPasswordSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      resetPassword(props.resetLink, values.newPass)
        .then((res) => {
            // enqueueSnackbar("パスワードが変更されています。", { variant: "success" });   
        //   setSuccessMessage(res.data.message);
          setSuccess(true);
          setIsRequested(true);
        })
        .catch((err) => {
          setIsRequested(false);
          setSuccess(false);
          setSubmitting(false);
          setStatus(err.error);
        });
    },
  });

  return (
    <>
      <div>
        {success && (
            <h4 className="text-white"> パスワードが変更されています。</h4>
        )}

        {isRequested && (
          <Link to="/auth">
            <button
              type="button"
              id="kt_login_forgot_cancel"
              className="btn btn-light-primary font-weight-bold px-9 py-4 my-3 mx-4"

            >
              ログインページへ移動する
            </button>
          </Link>
        )}
      </div>

      {!isRequested && (
        <div className="login-form login-forgot" style={{ display: "block" }}>
          <div className="text-center mb-10 mb-lg-20">
            <h3 className="font-size-h1 text-white">パスワードを再設定する</h3>
            {/* <div className="text-muted font-weight-bold">
              <FormattedMessage id="AUTH.FORGOT.DESC" />
      </div> */}
          </div>
          <form
            onSubmit={formik.handleSubmit}
            className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
          >
            {formik.status && (
              <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                <div className="alert-text font-weight-bold">
                  {formik.status}
                </div>
              </div>
            )}

            <div className="form-group fv-plugins-icon-container">
              <input
                placeholder="新しいパスワード"
                type="password"
                className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                  "password"
                )}`}
                name="newPass"
                {...formik.getFieldProps("newPass")}
              />
              {formik.touched.newPass && formik.errors.newPass ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{formik.errors.newPass}</div>
                </div>
              ) : null}
            </div>
            <div className="form-group fv-plugins-icon-container">
              <input
                placeholder="新しいパスワードを確認"
                type="password"
                className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                  "password"
                )}`}
                name="confirmPass"
                {...formik.getFieldProps("confirmPass")}
              />
              {formik.touched.confirmPass && formik.errors.confirmPass ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">
                    {formik.errors.confirmPass}
                  </div>
                </div>
              ) : null}
            </div>
         
            <div className="form-group d-flex flex-wrap flex-center">
              <button
                id="kt_login_forgot_submit"
                type="submit"
                className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
                disabled={formik.isSubmitting}
              >
                <FormattedMessage id="AUTH.GENERAL.SUBMIT_BUTTON" />
              </button>
              <Link to="/auth">
                <button
                  type="button"
                  id="kt_login_forgot_cancel"
                  className="btn btn-light-primary font-weight-bold px-9 py-4 my-3 mx-4"
                >
                  <FormattedMessage id="CANCEL" />
                </button>
              </Link>
            </div>
          </form>
        </div>
      )}
    </>
  );
}

export default injectIntl(connect(null, auth.actions)(ResetPassword));


