import React from "react";
import "antd/dist/antd.css";
import { Button,  Form, Select, Input, Divider } from "antd";
import { Modal } from "react-bootstrap";
import CreateAbleSelect from "react-select/creatable";
import { useIntl } from "react-intl";
import { TextareaAutosize } from "@material-ui/core";
import 'react-phone-number-input/style.css'
import copy from "copy-to-clipboard"; 
import PhoneInput from 'react-phone-number-input'
import DatePicker from 'react-date-picker';
import { useEffect, useState } from "react";
import moment from "moment";
import { useSnackbar } from "notistack";
export  function SellerUpdateDialog(props) {
  const { TextArea } = Input;
  const { state, setState, updateTask } =
    props;
  const color = { color: "red" };
  const userOptions = [];
  const intl = useIntl();
  const { enqueueSnackbar } = useSnackbar();

 
    const [copyText, setCopyText] = useState('');
  
    const handleCopyText = (e) => {
       setCopyText(e.target.value);
    } 
    
    const copyToClipboard = (e) => {
     
       copy(state.sellerPhoneNumber);
       enqueueSnackbar(`コピーされました`, { variant: "info" });
      //  alert(`Seller phone number copied "${state.sellerPhoneNumber}"`);
    }
    const [error, setError] = useState(false);
    const [error1, setError1] = useState(false);
    const [error2, setError2] = useState(false);

   

  return (
    // <Modal
    //   title="業務内容を更新"
    //   visible={props.isEditModal}
    //   footer={null}
    //   onCancel={props.handleCancel}
    //   style={{width:"900px"}}
    // >
      <Modal
      size="xl"
      centered
      show={props.isEditModal}
      onHide={props.handleCancel}
      scrollable={true}
      aria-labelledby="example-modal-sizes-title-lg"

    >
       <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">
          {" "}
          {intl.formatMessage({ id: "Sellerdetails" })}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
      
      <Form method="post" layout="vertical" /*onFinish={onFinish}*/>
      <div className="form-group row">
                  <div className="col-lg-12">
                    {" "}
                    <Divider>   {intl.formatMessage({ id: "Seller" })}</Divider>
                  </div>
                </div>
      
      

          <div className="form-group row">
            <div className="col-lg-4">
            <label className="col col-form-label">
            <span>{intl.formatMessage({ id: "Sellername" })}</span>
          </label>
              <input
                className={`ant-input form-control form-control-lg form-control-solid `}
                onChange={(e) => setState({ ...state, username: e.target.value })}
                value={state.username}
                disabled
              />

            </div>
            <div className="col-lg-4">
              <label className="col col-form-label">
                <span>{intl.formatMessage({ id: "sellerKatakana" })}</span>
              </label>
              <input
                className={`ant-input form-control form-control-lg form-control-solid `}
                onChange={(e) => {
                  if (

                    // /^([ア-ン]+)$/
                    /^([\u3000-\u303f\u30a0-\u30ff\uff00-\uff9f\s\-\(\)\.]+)$/.test(e.target.value) ||
                    e.target.value === ""

                  ) {
                    setError(false)
                    // setState({ ...state, sellerKatakana: e.target.value });
                  }

                  else if (
                    !/^([\u3000-\u303f\u30a0-\u30ff\uff00-\uff9f\s\-\(\)\.]+)$/.test(e.target.value)

                  ) {
                    setError(true)
                  }
                  setState({ ...state, sellerKatakana: e.target.value });
                }}
                value={state.sellerKatakana}

              />
              {error && (
                <p style={{ color: "red" }}> "フリガナ" はカタカナで入力してください。</p>
              )}
              

            </div>
            <div className="col-lg-4">
            <label className="col col-form-label">
            <span>{intl.formatMessage({ id: "Selleremail" })}</span>
          </label>
              <input
                className={`ant-input form-control form-control-lg form-control-solid `}
                onChange={(e) => setState({ ...state, email: e.target.value })}
                value={state.email}
                disabled
              />

            </div>

          
         
          </div>

         {/* -------------------------------------Second Panel--------------------------------------------- */}

         <div className="form-group row">
            <div className="col-lg-4">
            <label className="col col-form-label">
            <span>{intl.formatMessage({ id: "SelleraddressPostalcode" })}</span>
          </label>
              <input
                type="number"
                className={`ant-input form-control form-control-lg form-control-solid `}
                onChange={(e) => setState({ ...state, sellerPostalCode: e.target.value })}
                value={state.sellerPostalCode}
              />

            </div>
            <div className="col-lg-4">
            <label className="col col-form-label">
            <span>{intl.formatMessage({ id: "Selleraddress" })}</span>
          </label>
              <input
                className={`ant-input form-control form-control-lg form-control-solid `}
                onChange={(e) => setState({ ...state, sellerAddress: e.target.value }) }
                value={state.sellerAddress}
              />
            </div>
            <div className="col-lg-4"  >
            <label className="col col-form-label">
            <span>{intl.formatMessage({ id: "Sellerphoneno" })}</span>
            {/* <i class="material-icons" ></i> */}
            <i class="fa fa-clone"   onClick={copyToClipboard}></i>
          </label>
              {/* <PhoneInput
                defaultCountry="US"
                className={`ant-input form-control form-control-lg form-control-solid `}
                onChange={(e) => setState({ ...state, sellerPhoneNumber: e.target.value })}
                value={state.sellerPhoneNumber} /> */}
              <input
                type="tel"
                id="myInput"
                className={`ant-input form-control form-control-lg form-control-solid `}
                onChange={(e) => setState({ ...state, sellerPhoneNumber: e.target.value })}
                value={state.sellerPhoneNumber}
              />
              {/* <button onClick={copyToClipboard}> click</button> */}
            </div>
          </div>
      

     {/* -------------------------------------third Panel--------------------------------------------- */}
          <div className="form-group row">
           
            <div className="col-lg-4">
            <label className="col-xl-4 col-lg-4 col-form-label">
            <span>{intl.formatMessage({ id: "CREATEDAT" })}</span>
          </label>
              <input
                className={`form-control form-control-lg form-control-solid `}
                onChange={(e) => setState({ ...state, createdAt: e.target.value })}
                value={moment(`${state.createdAt}`).format("YYYY/MM/DD")}
                disabled
              />

            </div>
            <div className="col-lg-4">
            <label className="col-xl-4 col-lg-4 col-form-label">
            <span>{intl.formatMessage({ id: "Gender" })}</span>
          </label>
          <select  className={`ant form-control form-control-lg form-control-solid `} id="lang" onChange={(e) => setState({ ...state, gender: e.target.value })} value={state.gender}>
                  <option value=" ">   {intl.formatMessage({ id: "selectgender" })}</option>
                  <option value="M">{intl.formatMessage({ id: "male" })}</option>
                  <option value="F">{intl.formatMessage({ id: "female" })}</option>
               </select>
              {/* <input
                className={`ant-input form-control form-control-lg form-control-solid `}
                onChange={(e) => setState({ ...state, gender: e.target.value })}
                value={state.gender}
              /> */}
            </div>
            <div className="col-lg-4">
            <label className="col col-form-label">
            <span>{intl.formatMessage({ id: "Disabilitycategory" })}</span>
          </label>
          <select  className={`ant form-control form-control-lg form-control-solid `} id="lang" onChange={(e) => setState({ ...state, disabilityCategory: e.target.value })} value={state.disabilityCategory}>
                  <option >   {intl.formatMessage({ id: "selectdisablecategory" })}</option>
                  <option value="physical">{intl.formatMessage({ id: "physical" })}</option>
                  <option value="intellectual">{intl.formatMessage({ id: "intellectual" })}</option>
                  <option value="mental">{intl.formatMessage({ id: "mental" })}</option>
                  <option value="none">{intl.formatMessage({ id: "none" })}</option>
               </select>
             
            </div>
           
          </div>



          <div className="form-group row">
            
           
           
            <div className="col-lg-4">
            <label className="col col-form-label">
            <span>{intl.formatMessage({ id: "Disabilitycertificate" })}</span>
          </label>
          <select  className={`ant form-control form-control-lg form-control-solid `} id="lang" onChange={(e) => setState({ ...state, disabilityCertificate: e.target.value })} value={state.disabilityCertificate}>
                  <option value=" ">{intl.formatMessage({ id: "selectdisabilitycertificate" })}</option>
                  <option value="Yes">{intl.formatMessage({ id: "yes" })}</option>
                  <option value="No">{intl.formatMessage({ id: "No" })}</option>
                 
               </select>
              
            </div>
             
            <div className="col-lg-4">
            <label className="col-xl-4 col-lg-4 col-form-label">
            <span>{intl.formatMessage({ id: "Dateofbirth" })}</span>
          </label>
                    
              <input
                type="date"        
                placeholder="yyyy-mm-dd"
                className={`ant-input form-control form-control-lg form-control-solid `}
                onChange={(e) => setState({ ...state, dob: e.target.value })}
                value={moment(`${state.dob}`).format("YYYY-MM-DD")}
              />
              
            </div>
          </div>

     {/* -------------------------------------fourth Panel--------------------------------------------- */}
     <div className="col-lg-12">
                    {" "}
                    <Divider>{intl.formatMessage({ id: "SellerGuardianDetails" })}</Divider>
                  </div>
          <div className="form-group row">
          <div className="col-lg-4">
            <label className="col col-form-label">
            <span>{intl.formatMessage({ id: "Nameofguardian" })}</span>
          </label>
              <input
                className={`ant-input form-control form-control-lg form-control-solid `}
                onChange={(e) => setState({ ...state, guardianName: e.target.value })}
                value={state.guardianName}
                
              />

            </div>
            <div className="col-lg-4">
            <label className="col col-form-label">
            <span>{intl.formatMessage({ id: "guardianKatakana" })}</span>
          </label>
              <input
                className={`ant-input form-control form-control-lg form-control-solid `}
                onChange={(e) => {
                  // setState({ ...state, guardianName: e.target.value })
                  if (
                    // /[ァ-ヴー]+/u.test(e.target.value)
                   
                    /^([\u3000-\u303f\u30a0-\u30ff\uff00-\uff9f\s\-\(\)]+)$/.test(e.target.value) 
                    ||
                    e.target.value === ""
                    
                  ) {
                    setError1(false)
                   
                  }
                  else if (
                    !/^([\u3000-\u303f\u30a0-\u30ff\uff00-\uff9f\s\-\(\)]+)$/.test(e.target.value) 

                  ) {
                    setError1(true)
                  }
                  setState({ ...state, guardianKatakana: e.target.value });
                }}
                // onChange={(e) => setState({ ...state, guardianName: e.target.value })}
                value={state.guardianKatakana}
              />
                 {error1 && (
                <p style={{ color: "red" }}> "フリガナ" はカタカナで入力してください。</p>
              )}

            </div>
            <div className="col-lg-4">
            <label className="col col-form-label">
            <span>{intl.formatMessage({ id: "Parentemail" })}</span>
          </label>
              <input
                className={`ant-input form-control form-control-lg form-control-solid `}
                onChange={(e) => setState({ ...state, guardianEmailAddress: e.target.value })}
                value={state.guardianEmailAddress}
                type="email"
              />
            </div>
            
          
           
          </div>
 
      {/* -------------------------------------fifth Panel--------------------------------------------- */}


          <div className="form-group row">
          <div className="col-lg-4">
            <label className="col col-form-label">
            <span>{intl.formatMessage({ id: "Livingstatus" })}</span>
          </label>
          <select  className={`ant form-control form-control-lg form-control-solid `} id="lang" onChange={(e) => setState({ ...state, livingStatus: e.target.value })} value={state.livingStatus}>
                  <option value=" ">{intl.formatMessage({ id: "selectlivingstatus" })}</option>
                  <option value="separately">{intl.formatMessage({ id: "livingseparately" })}</option>
                  <option value="together">{intl.formatMessage({ id: "livingtogether" })}</option>
               </select>
              {/* <input
                className={`ant-input form-control form-control-lg form-control-solid `}
                onChange={(e) => setState({ ...state,livingStatus: e.target.value })}
                value={state.livingStatus}
              /> */}

            </div>
         
            <div className="col-lg-4">
            <label className="col col-form-label">
            <span>{intl.formatMessage({ id: "Postalcodeguardian" })}</span>
          </label>
              <input
              type="number"
                className={`ant-input form-control form-control-lg form-control-solid `}
                onChange={(e) => setState({ ...state, guardianPostalCode: e.target.value })}
                value={state.guardianPostalCode}
                disabled={state.livingStatus === "together" }
              />
            </div>
            <div className="col-lg-4">
            <label className="col col-form-label">
            <span>{intl.formatMessage({ id: "Addressguardian" })}</span>
          </label>
              <input
                className={`ant-input form-control form-control-lg form-control-solid `}
                onChange={(e) => setState({ ...state, guardianAddress: e.target.value })}
                value={state.guardianAddress}
                disabled={state.livingStatus === "together" }
              />
            </div>
          </div>

     {/* -------------------------------------Sixth Panel--------------------------------------------- */}

          <div className="form-group row">
          <div className="col-lg-4">
            <label className="col-xl-4 col-lg-4 col-form-label">
            <span>{intl.formatMessage({ id: "Gender" })}</span>
          </label>
          <select  className={`ant form-control form-control-lg form-control-solid `} id="lang" onChange={(e) => setState({ ...state, guardianGender: e.target.value })} value={state.guardianGender}>
                  <option value=" ">{intl.formatMessage({ id: "selectgender" })}</option>
                  <option value="M">{intl.formatMessage({ id: "male" })}</option>
                  <option value="F">{intl.formatMessage({ id: "female" })}</option>
               </select>
           
            </div>
          <div className="col-lg-4">
            <label className="col col-form-label">
            <span>{intl.formatMessage({ id: "Relationshipseller" })}</span>
          </label>
          <select  className={`ant form-control form-control-lg form-control-solid `} id="lang" onChange={(e) => setState({ ...state, relationToSeller: e.target.value })} value={state.relationToSeller}>
                  <option value=" ">{intl.formatMessage({ id: "selectrelationshipseller" })}</option>
                  <option value="father">{intl.formatMessage({ id: "father" })}</option>
                  <option value="mother">{intl.formatMessage({ id: "mother" })}</option>
                  <option value="grandfather">{intl.formatMessage({ id: "grandfather" })}</option>
                  <option value="grandmother">{intl.formatMessage({ id: "grandmother" })}</option>
                  <option value="other">{intl.formatMessage({ id: "other" })}</option>
                  
               </select>
             
            </div>
            <div className="col-lg-4">
            <label className="col col-form-label">
            <span>{intl.formatMessage({ id: "Parentphone" })}</span>
            <i class="fa fa-clone"   onClick={copyToClipboard}></i>
          </label>
              <input
                type="tel"
                className={`ant-input form-control form-control-lg form-control-solid `}
                onChange={(e) => setState({ ...state, guardianPhoneNumber: e.target.value })}
                value={state.guardianPhoneNumber}
              />

            </div>
           
            <div className="col-lg-4">
            <label className="col col-form-label">
            <span>{intl.formatMessage({ id: "Remarks" })}</span>
          </label>
          <textarea  className={`ant form-control form-control-lg form-control-solid `} id="lang" onChange={(e) => setState({ ...state, remarks: e.target.value })} value={state.remarks}>
                  
               </textarea>
            

            </div>
           
           
          </div>

      
      </Form>
      </Modal.Body>
      <br />
      <Modal.Footer>
      <div className="row">
              <button
                type="button"
                onClick={props.handleCancel}
                className="btn btn-light btn-elevate"
              >
                {intl.formatMessage({ id: "cancel" })}
              </button>

              <button
                type="button"
                className="btn btn-delete btn-primary"
                
                style={{ marginLeft: "5px" }}
                onClick={(e) => {
                  e.preventDefault();
                  console.log("sssseeeee");
                  updateTask({ data: state });
                }}
                // onClick={updateTask}
                // onClick={(e) => {
                //   e.preventDefault();
                //   console.log("ssss");
                //   updateProduct({ data: ownState });
                // }}
              >
                {intl.formatMessage({ id: "update" })}
              </button>
            </div>
      </Modal.Footer>
      {/* <Button onClick={updateTask}>更新</Button> */}
    </Modal>
  );
}
