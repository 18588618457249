// import React, { useState } from "react";
// import { useFormik } from "formik";
// import { connect } from "react-redux";
// import { Link, Redirect } from "react-router-dom";
// import * as Yup from "yup";
// import { injectIntl } from "react-intl";
// import * as auth from "../_redux/authRedux";
// import { requestPassword } from "../_redux/authCrud";
// import { FormattedMessage} from "react-intl";

// const initialValues = {
//   email: "",
// };

// function ForgotPassword(props) {
//   const { intl } = props;
//   const [isRequested, setIsRequested] = useState(false);
//   const ForgotPasswordSchema = Yup.object().shape({
//     email: Yup.string()
//       .email("メールアドレスの形式で入力してください。")
//       .min(3, "Minimum 3 symbols")
//       .max(50, "Maximum 50 symbols")
//       .required(
//         intl.formatMessage({
//           id: "AUTH.VALIDATION.REQUIRED_FIELD",
//         })
//       ),
//   });

//   const getInputClasses = (fieldname) => {
//     if (formik.touched[fieldname] && formik.errors[fieldname]) {
//       return "is-invalid";
//     }

//     if (formik.touched[fieldname] && !formik.errors[fieldname]) {
//       return "is-valid";
//     }

//     return "";
//   };

//   const formik = useFormik({
//     initialValues,
//     validationSchema: ForgotPasswordSchema,
//     onSubmit: (values, { setStatus, setSubmitting }) => {
//       requestPassword(values.email)
//         .then(() => setIsRequested(true))
//         .catch(() => {
//           setIsRequested(false);
//           setSubmitting(false);
//           setStatus(
//             intl.formatMessage(
//               { id: "AUTH.VALIDATION.NOT_FOUND" },
//               { name: values.email }
//             )
//           );
//         });
//     },
//   });

//   return (
//     <>
//       {isRequested && <Redirect to="/auth" />}
//       {!isRequested && (
//         <div className="login-form login-forgot" style={{ display: "block" }}>
//           <div className="text-center mb-10 mb-lg-20">
//             <h3 className="font-size-h1">  <FormattedMessage id="AUTH.FORGOT.TITLE" /></h3>
//             <div className="text-muted font-weight-bold">
//             <FormattedMessage id="AUTH.FORGOT.DESC" />
//             </div>
//           </div>
//           <form
//             onSubmit={formik.handleSubmit}
//             className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
//           >
//             {formik.status && (
//               <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
//                 <div className="alert-text font-weight-bold">
//                   {formik.status}
//                 </div>
//               </div>
//             )}
//             <div className="form-group fv-plugins-icon-container">
//               <input
//                 type="email"
//                 className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
//                   "email"
//                 )}`}
//                 name="email"
//                 {...formik.getFieldProps("email")}
//               />
//               {formik.touched.email && formik.errors.email ? (
//                 <div className="fv-plugins-message-container">
//                   <div className="fv-help-block">{formik.errors.email}</div>
//                 </div>
//               ) : null}
//             </div>
//             <div className="form-group d-flex flex-wrap flex-center">
//               <button
//                 id="kt_login_forgot_submit"
//                 type="submit"
//                 className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
//                 disabled={formik.isSubmitting}
//               >
//                    <FormattedMessage id="AUTH.GENERAL.SUBMIT_BUTTON" />
//               </button>
//               <Link to="/auth">
//                 <button
//                   type="button"
//                   id="kt_login_forgot_cancel"
//                   className="btn btn-light-primary font-weight-bold px-9 py-4 my-3 mx-4"
//                 >
//                    <FormattedMessage id="CANCEL" />
//                 </button>
//               </Link>
//             </div>
//           </form>
//         </div>
//       )}
//     </>
//   );
// }

// export default injectIntl(connect(null, auth.actions)(ForgotPassword));


// import React, { useState } from "react";
// import { useFormik } from "formik";
// import { connect } from "react-redux";
// import { Link, Redirect } from "react-router-dom";
// import * as Yup from "yup";
// import { injectIntl } from "react-intl";
// import * as auth from "../_redux/authRedux";
// import { requestPassword } from "../_redux/authCrud";
// import { FormattedMessage } from "react-intl";
// import { useSnackbar } from "notistack";
// // import SuccessMessage from "../../../../../src/_metronic/layout/components/SuccessMessage";

// const initialValues = {
//   email: "",
// };

// function ForgotPassword(props) {
//   const { intl } = props;
//   const [isRequested, setIsRequested] = useState(false);
//   const [successMessage, setSuccessMessage] = useState("");
//   const [success, setSuccess] = useState(false);
//   const { enqueueSnackbar } = useSnackbar();
//   const ForgotPasswordSchema = Yup.object().shape({
//     email: Yup.string()
//       .email("Wrong email format")
//       .min(3, "Minimum 3 symbols")
//       .max(50, "Maximum 50 symbols")
//       .required(
//         intl.formatMessage({
//           id: "AUTH.VALIDATION.REQUIRED_FIELD",
//         })
//       ),
//   });

//   const getInputClasses = (fieldname) => {
//     if (formik.touched[fieldname] && formik.errors[fieldname]) {
//       return "is-invalid";
//     }

//     if (formik.touched[fieldname] && !formik.errors[fieldname]) {
//       return "is-valid";
//     }

//     return "";
//   };

//   const onClose = () => {
//     console.log("clicked");
//     setSuccess(false);
//   };

//   const formik = useFormik({
//     initialValues,
//     validationSchema: ForgotPasswordSchema,
//     onSubmit: (values, { setStatus, setSubmitting }) => {
//       requestPassword(values.email)
//         .then((res) => {
//           enqueueSnackbar(res.data.message, { variant: "success" });
//           // setSuccessMessage(res.data.message);
//           setSuccess(true);
//           setIsRequested(true);
//         })
//         .catch(() => {
//           setIsRequested(false);
//           setSuccess(false);
//           setSubmitting(false);
//           setStatus(
//             intl.formatMessage(
//               { id: "AUTH.VALIDATION.NOT_FOUND" },
//               { name: values.email }
//             )
//           );
//         });
//     },
//   });

//   return (
//     <>
//       <div>
//         {/* {success && (
//           <SuccessMessage message={successMessage} onClose={onClose} />
//         )} */}

//         {isRequested && (
//           <Link to="/auth">
//             <button
//               type="button"
//               id="kt_login_forgot_cancel"
//               className="btn btn-light-primary font-weight-bold px-9 py-4 my-3 mx-4"
//             >
//               Go to Login Page
//             </button>
//           </Link>
//         )}
//       </div>

//       {!isRequested && (
//         <div className="login-form login-forgot" style={{ display: "block" }}>
//           <div className="text-center mb-10 mb-lg-20">
//             <h3 className="font-size-h1" style={{  color:"#ffff" }}>
//               {" "}
//               <FormattedMessage id="AUTH.FORGOT.TITLE" />
//             </h3>
//             <div className="text-muted font-weight-bold" style={{  color:"#ffff" }}>
//               <FormattedMessage id="AUTH.FORGOT.DESC" />
//             </div>
//           </div>
//           <form
//             onSubmit={formik.handleSubmit}
//             className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
//           >
//             {formik.status && (
//               <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
//                 <div className="alert-text font-weight-bold">
//                   {formik.status}
//                 </div>
//               </div>
//             )}
//             <div className="form-group fv-plugins-icon-container">
//               <input
//                 type="email"
//                 className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
//                   "email"
//                 )}`}
//                 name="email"
//                 {...formik.getFieldProps("email")}
//               />
//               {formik.touched.email && formik.errors.email ? (
//                 <div className="fv-plugins-message-container">
//                   <div className="fv-help-block">{formik.errors.email}</div>
//                 </div>
//               ) : null}
//             </div>
//             <div className="form-group d-flex flex-wrap flex-center">
//               <button
//                 id="kt_login_forgot_submit"
//                 type="submit"
//                 className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
//                 disabled={formik.isSubmitting}
//               >
//                 <FormattedMessage id="AUTH.GENERAL.SUBMIT_BUTTON" />
//               </button>
//               <Link to="/auth">
//                 <button
//                   type="button"
//                   id="kt_login_forgot_cancel"
//                   className="btn btn-light-primary font-weight-bold px-9 py-4 my-3 mx-4"
//                 >
//                   <FormattedMessage id="CANCEL" />
//                 </button>
//               </Link>
//             </div>
//           </form>
//         </div>
//       )}
//     </>
//   );
// }

// export default injectIntl(connect(null, auth.actions)(ForgotPassword));




import React, { useState } from "react";
import { useFormik } from "formik";
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import * as Yup from "yup";
import { injectIntl } from "react-intl";
import * as auth from "../_redux/authRedux";
import { requestPassword } from "../_redux/authCrud";
import { FormattedMessage } from "react-intl";
// import SuccessMessage from "../../../../../src/_metronic/layout/components/SuccessMessage";

const initialValues = {
  email: "",
};

function ForgotPassword(props) {
  const { intl } = props;
  const [isRequested, setIsRequested] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [success, setSuccess] = useState(false);
  const ForgotPasswordSchema = Yup.object().shape({
    email: Yup.string()
      .email("Wrong email format")
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
  });

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const onClose = () => {
    console.log("clicked");
    setSuccess(false);
  };

  const formik = useFormik({
    initialValues,
    validationSchema: ForgotPasswordSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      requestPassword(values.email)
        .then((res) => {
          setSuccessMessage(res.data.message);
          setSuccess(true);
          setIsRequested(true);
        })
        .catch(() => {
          setIsRequested(false);
          setSuccess(false);
          setSubmitting(false);
          setStatus(
            intl.formatMessage(
              { id: "AUTH.VALIDATION.NOT_FOUND" },
              { name: values.email }
            )
          );
        });
    },
  });

  return (
    <>
      <div style={{marginBottom:"300px"}}>
        {success && (
        <h4 className="text-white"> メールが送信されました。受信ボックスをご確認ください。</h4>
        )}

        {isRequested && (
          <Link to="/auth">
            <button
              type="button"
              id="kt_login_forgot_cancel"
              className="btn btn-light-primary font-weight-bold px-9 py-4 my-3 mx-4"
            >
             ログインページへ移動する
            </button>
          </Link>
        )}

{!isRequested && (
        <div className="login-form login-forgot " style={{ display: "block" }}>
          <div className="text-center mb-10 mb-lg-20">
            <h3 className="font-size-h1 text-white">
              {" "}
              <FormattedMessage id="AUTH.FORGOT.TITLE" />
            </h3>
            <div className="text-white font-weight-bold">
              <FormattedMessage id="AUTH.FORGOT.DESC" />
            </div>
          </div>
          <form
            onSubmit={formik.handleSubmit}
            className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
          >
            {formik.status && (
              <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                <div className="alert-text font-weight-bold">
                  {formik.status}
                </div>
              </div>
            )}
            <div className="form-group fv-plugins-icon-container">
              <input
                type="email"
                className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                  "email"
                )}`}
                name="email"
                {...formik.getFieldProps("email")}
              />
              {formik.touched.email && formik.errors.email ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{formik.errors.email}</div>
                </div>
              ) : null}
            </div>
          
            <div className="form-group d-flex flex-wrap flex-center">
              <button
                id="kt_login_forgot_submit"
                type="submit"
                className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
                disabled={formik.isSubmitting}
              >
                <FormattedMessage id="AUTH.GENERAL.SUBMIT_BUTTON" />
              </button>
              <Link to="/auth">
                <button
                  type="button"
                  id="kt_login_forgot_cancel"
                  className="btn btn-light-primary font-weight-bold px-9 py-4 my-3 mx-4"
                >
                  <FormattedMessage id="CANCEL" />
                </button>
              </Link>
            </div>
          </form>
        </div>
      )}
      </div>

   
    </>
  );
}

export default injectIntl(connect(null, auth.actions)(ForgotPassword));
