// import React from "react";
// import "antd/dist/antd.css";
// import { Button,  Form, Select, Input, Divider } from "antd";
// import { Modal } from "react-bootstrap";
// import CreateAbleSelect from "react-select/creatable";
// import { useIntl } from "react-intl";
// import { TextareaAutosize } from "@material-ui/core";
// import 'react-phone-number-input/style.css'
// import PhoneInput from 'react-phone-number-input'
// import DatePicker from 'react-date-picker';

// import moment from "moment";

// export  function OrderDialog(props) {
//   const { TextArea } = Input;
//   const { state, setState, updateTask } =
//     props;
//   const color = { color: "red" };
//   const userOptions = [];
//   const intl = useIntl();

//   return (
//     // <Modal
//     //   title="業務内容を更新"
//     //   visible={props.isEditModal}
//     //   footer={null}
//     //   onCancel={props.handleCancel}
//     //   style={{width:"900px"}}
//     // >
//       <Modal
//       size="sm"
//       centered
//       show={props.isEditModal}
//       onHide={props.handleCancel}
//       scrollable={true}
//       aria-labelledby="example-modal-sizes-title-lg"

//     >
//        <Modal.Header closeButton>
//         <Modal.Title id="example-modal-sizes-title-lg">
//           {" "}
//           {intl.formatMessage({ id: "Customer Address" })}
//         </Modal.Title>
//       </Modal.Header>
//       <Modal.Body>

//       <Form method="post" layout="vertical" /*onFinish={onFinish}*/>
//       <div className="form-group row">
//                   {/* <div className="col-lg-12">
//                     {" "}
//                     <Divider>   {intl.formatMessage({ id: "Seller" })}</Divider>
//                   </div> */}
//                 </div>

//           <div className="form-group row">
//             <div className="col-lg-8">
//             <label className="col col-form-label">
//             <span>{intl.formatMessage({ id: "Customer Address" })}</span>
//           </label>
//           <label className="col col-form-label">
//             <span>address</span>
//           </label>
//               {/* <input
//                 className={`ant-input form-control form-control-lg form-control-solid `}
//                 onChange={(e) => setState({ ...state, username: e.target.value })}
//                 value={state.username}
//               /> */}

//             </div>
//             </div>

//          {/* -------------------------------------Second Panel--------------------------------------------- */}

//      {/* -------------------------------------third Panel--------------------------------------------- */}

//      {/* -------------------------------------fourth Panel--------------------------------------------- */}

//       {/* -------------------------------------fifth Panel--------------------------------------------- */}

//      {/* -------------------------------------Sixth Panel--------------------------------------------- */}

//       </Form>
//       </Modal.Body>
//       <br />
//       {/* <Modal.Footer>
//       <div className="row">
//               <button
//                 type="button"
//                 onClick={props.handleCancel}
//                 className="btn btn-light btn-elevate"
//               >
//                 {intl.formatMessage({ id: "cancel" })}
//               </button>

//               <button
//                 type="button"
//                 className="btn btn-delete btn-primary"

//                 style={{ marginLeft: "5px" }}
//                 onClick={(e) => {
//                   e.preventDefault();
//                   console.log("sssseeeee");
//                   updateTask({ data: state });
//                 }}
//                 // onClick={updateTask}
//                 // onClick={(e) => {
//                 //   e.preventDefault();
//                 //   console.log("ssss");
//                 //   updateProduct({ data: ownState });
//                 // }}
//               >
//                 {intl.formatMessage({ id: "update" })}
//               </button>
//             </div>
//       </Modal.Footer> */}
//       {/* <Button onClick={updateTask}>更新</Button> */}
//     </Modal>
//   );
// }

import React, { useState, useEffect } from "react";

import { Modal, Row, Col, Container, Button } from "react-bootstrap";

const OrderDialog = (props) => {
  useEffect(() => {
    console.log("propsss", props.customer);
  }, []);
  return (
    <div>
      <Modal {...props} aria-labelledby="example-modal-sizes-title-lg">
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            購入者詳細
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="show-grid">
          <Container>
            <Row>
              <Col xs={6}>購入者名</Col>
              <Col xs={6}>
                {props.customer.customerName
                  ? props.customer.customerName.split(" ")[1]
                    ? props.customer.customerName.split(" ")[1] +
                      " " +
                      props.customer.customerName.split(" ")[0]
                    : props.customer.customerName.split(" ")[0]
                  : "–––"}
              </Col>
            </Row>
            <br />
            <Row>
              <Col xs={6}>購入者電話番号</Col>
              <Col xs={6}>
                {props.customer.customerPhone
                  ? props.customer.customerPhone
                  : "–––"}{" "}
              </Col>
            </Row>
            <br />
            <Row>
              <Col xs={6}>購入者住所</Col>
              <Col xs={6}>
                {props.customer.customerAddress
                  ? props.customer.customerAddress
                  : "–––"}{" "}
              </Col>
            </Row>

            <br />
            <Row>
              <Col xs={6}>追跡番号</Col>
              <Col xs={6}>
                {props.customer.truckingNumber
                  ? props.customer.truckingNumber
                  : "–––"}{" "}
              </Col>
            </Row>
            <br />
            <Row>
              <Col xs={6}>運送会社名</Col>
              <Col xs={6}>
                {props.customer.logisticsCompany
                  ? props.customer.logisticsCompany
                  : "–––"}{" "}
              </Col>
            </Row>
            {/* <Row>
              <Col xs={6}>購入者電話番号</Col>
              <Col xs={6}>
                {props.customer.customerPhoneNo
                  ? props.customer.customerPhoneNo
                  : "–––"}{" "}
              </Col>
            </Row> */}
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={props.onHide}>キャンセル</Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default OrderDialog;
