/* eslint-disable jsx-a11y/role-supports-aria-props */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl, checkIsActive } from "../../../../_helpers";
import { useIntl } from "react-intl";
import "./Style2.css"

export function AsideMenuList({ layoutProps }) {
  const location = useLocation();
  const intl = useIntl();
  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
      ? ` ${
          !hasSubmenu && "menu-item-active"
        } menu-item-open menu-item-not-hightlighted`
      : "";
  };

  return (
    <>
      {/* begin::Menu Nav */}
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        {/*begin::1 Level*/}
        {/* <li
          className={`menu-item ${getMenuItemActive("/dashboard", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/dashboard">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")} />
            </span>
            <span className="menu-text">Dashboard</span>
          </NavLink>
        </li> */}
        {/*end::1 Level*/}

        {/*begin::1 Level*/}
        <li
          className={`menu-item ${getMenuItemActive("/dashboard", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/dashboard">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Design/layers.svg")} />
            </span>
            <span className="menu-text" style={{fontSize:"15px"}}>
              {intl.formatMessage({ id: "MENU.DASHBOARD" })}
            </span>
          </NavLink>
        </li>

        <li className={`menu-item ${getMenuItemActive("/sellers", true)}`}>
          <NavLink className="menu-link menu-toggle" to="/sellers">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/General/User.svg")} />
            </span>
            <span className="menu-text" style={{fontSize:"15px"}}>
              {intl.formatMessage({ id: "SELLERS" })}
            </span>
          </NavLink>
        </li>
        
        <li className={`menu-item ${getMenuItemActive("/products", true)}`}>
          <NavLink className="menu-link menu-toggle" to="/products">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Home/Library.svg")} />
            </span>
            <span className="menu-text" style={{fontSize:"15px"}}>
              {intl.formatMessage({ id: "PRODUCTS" })}
            </span>
          </NavLink>
        </li>

        <li className={`menu-item ${getMenuItemActive("/orders", true)}`}>
          <NavLink className="menu-link menu-toggle" to="/orders">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl( "/media/svg/icons/Shopping/Cart2.svg")} />
            </span>
            <span className="menu-text" style={{fontSize:"15px"}}>
              {intl.formatMessage({ id: "ORDERS" })}
            </span>
          </NavLink>
        </li>
        <li className="asidehide" style={{marginLeft:"26px", marginTop:"10px"}}>
          <NavLink className="" to="/logout">
            <span className="svg-icon menu-icon">
              {/* <SVG src={toAbsoluteUrl( "/media/svg/icons/Shopping/Cart2.svg")} /> */}
              <img style={{height:"20px"}} src={toAbsoluteUrl("/media/svg/icons/General/logout1.png")} />
            </span>
            <span style={{fontSize:"16px",marginLeft:"13px",fontWeight:"500px", color:"#B2B4BB"}}>
              {intl.formatMessage({ id: "signout" })}
            </span>
          </NavLink>
        </li>
        
        {/* <li className={`menu-item ${getMenuItemActive("/settings", true)}`}>
          <NavLink className="menu-link menu-toggle" to="/settings">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Tools/Tools.svg")} />
            </span>
            <span className="menu-text">
              {intl.formatMessage({ id: "Settings" })}
            </span>
          </NavLink>
        </li> */}
        {/* 
        <li
          className={`menu-item menu-item-submenu ${getMenuItemActive(
            "/react-bootstrap",
            true
          )}`}
          aria-haspopup="true"
          data-menu-toggle="hover"
        >
          <NavLink className="menu-link menu-toggle" to="/setting">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Box2.svg")} />
            </span>
            <span className="menu-text">Settings</span>
            <i className="menu-arrow" />
          </NavLink>
        </li> */}
      </ul>

      {/* end::Menu Nav */}
    </>
  );
}
