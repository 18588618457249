import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import SVG from "react-inlinesvg";
import React from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../_metronic/_partials/controls";
import { sortCaret, toAbsoluteUrl } from "../../../_metronic/_helpers";
import { ButtonGroup, Button, Alert } from "react-bootstrap";
import OrdersTable from "../../components/OrderTable";
import axios from "axios";
import OrderDialog from "../../ordermodal/OrderDialog";
import { EyeOutlined } from "@ant-design/icons";
import moment from "moment";
import { Scrollbars } from "rc-scrollbars";
import PriceModal from "./PriceModal";
import EditShippedModal from "./EditShippedModal";
import { REACT_APP_API } from "../../../config";

export default function OrderCard() {
  const intl = useIntl();
  const [data, setData] = useState([]);
  const [customer, setCustomer] = useState({
    customerName: "",
    customerPhone: "",
    customerAddress: "",
    truckingNumber: "",
    logisticsCompany: "",
  });

  const [price, setPrice] = useState({
    order_id: null,
    subtotal: 0,
    total_discount: 0,
    total_shipping: 0,
    total_tax: 0,
    total_order_price: 0,
    item_discount: 0,
    item_price: 0,
    baseOrderPrice: 0,
    sellerWiseDiscount: [],
  });
  const [priceShow, setPriceShow] = useState(false);

  const [ShippingModalShow, setShippingModalShow] = useState(false);
  const [loader, setloader] = useState(false);
  const [Id, setId] = useState("");

  const fetchData = () =>
    axios.get(`${process.env.REACT_APP_API}admin/orders/`);

  const ff = async () => {
    var dd = await fetchData();
    console.log(dd.data.data);
    setData(dd.data.data);
  };

  useEffect(() => {
    ff();

    // console.log(data);
  }, []);

  const SubmitStatus2 = async (values) => {
    console.log(values);
    setloader(true);
    const BASE_URLs = `${REACT_APP_API}orders/tracking/${Id}`;
    // console.log("status will change");
    console.log(Id);
    console.log(values);
    try {
      const obj = await axios.put(BASE_URLs, {
        truckingNumber: values.TruckingNumber,
        logisticsCompany: values.LogisticsCompanyName,
      });
      setId("");
      setShippingModalShow(false);
      // setShowStatusChangeModal1(false);
      setloader(false);
      ff();
    } catch (err) {
      setId("");
      setShippingModalShow(false);
      // setShowStatusChangeModal1(false);
      setloader(false);
    }
  };

  const closeEditShippingModal = () => {
    setShippingModalShow(false);
  };

  const [isEditModal, setIsEditModal] = useState(false);
  const [dialog, setDialog] = useState({
    isOpen: false,
    // user_id: "",
    // ticketId: "",
    // id: "",
    // note: "",
    // image: "",
    // viewOnly: false,
  });

  const [dialog1, setDialog1] = useState({
    isOpen: false,
    // user_id: "",
    // ticketId: "",
    // id: "",
    // note: "",
    // image: "",
    // viewOnly: false,
  });

  var mydata = [
    {
      SellerID: "ORD1",
      SellerName: "Anjali",
      "Created At": "2021/06/06",
      Action: "",
      SellerStatus: "New",
    },
    {
      SellerID: "PRD1",
      SellerName: "Anjali",
      "Created At": "2021/04/06",
      Action: "",
      SellerStatus: "New",
    },
    {
      SellerID: "QRD1",
      SellerName: "Anjali",
      "Created At": "2021/06/06",
      Action: "",
      SellerStatus: "New",
    },
    {
      SellerID: "WRD1",
      SellerName: "Ginji",
      "Created At": "2021/07/06",
      Action: "",
      SellerStatus: "New",
    },
    {
      SellerID: "DRD1",
      SellerName: "Varun",
      "Created At": "2021/06/06",
      Action: "",
      SellerStatus: "New",
    },
    {
      SellerID: "ORD1",
      SellerName: "Biren",
      "Created At": "2021/08/06",
      Action: "",
      SellerStatus: "New",
    },
  ];

  const columnssss = React.useMemo(
    () => [
      {
        Header: "  ",
        columns: [
          {
            Header: "SellerID",
            accessor: "SellerID",
          },
          {
            Header: "SellerName",
            accessor: "SellerName",
          },

          {
            Header: "Action",
            accessor: "Action",
            Cell: () => (
              <>
                <ButtonGroup>
                  <button
                    className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
                    onClick={(e) => {
                      setDialog({
                        isOpen: true,
                        // ticketId: row.id,
                        // user_id: row.advertiser_id,
                      });
                    }}
                  >
                    {/* <a className="" href='#' onClick={() => uiProps.openUserProfile("23526")}> */}
                    <span className="symbol-label">
                      <SVG
                        className="svg-icon menu-icon"
                        style={{ fill: "blue", width: "17px" }}
                        src={toAbsoluteUrl("/media/svg/icons/Design/Edit.svg")}
                      />
                    </span>
                    {/* </a> */}

                    {/* <span>{data}</span> */}
                  </button>
                  <button
                    className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
                    onClick={(e) => {
                      setDialog1({
                        isOpen: true,
                        // ticketId: row.id,
                        // user_id: row.advertiser_id,
                      });
                    }}
                  >
                    {/* <a className="" href='#' onClick={() => uiProps.openUserProfile("23526")}> */}
                    <span className="symbol-label">
                      <SVG
                        className="svg-icon menu-icon"
                        style={{ height: "18px" }}
                        src={toAbsoluteUrl(
                          "/media/svg/icons/Design/delete.svg"
                        )}
                      />
                    </span>
                    {/* </a> */}

                    {/* <span>{data}</span> */}
                  </button>
                </ButtonGroup>
              </>
            ),
          },
        ],
      },
    ],
    []
  );

  const column = [
    {
      dataField: "SellerID",
      classes: "text-center",
      headerClasses: "text-center",
      text: intl.formatMessage({ id: "SELLERID" }),
      // formatter: (prop, row) => `${row.first_name} ${row.last_name}`,
    },
    {
      dataField: "name",
      classes: "text-center",
      headerClasses: "text-center",
      text: intl.formatMessage({ id: "SELLERNAME" }),
    },
    {
      dataField: "Created At",
      classes: "text-center",
      headerClasses: "text-center",
      text: intl.formatMessage({ id: "CREATEDAT" }),
    },
    {
      dataField: "SellerStatus",
      classes: "text-center",
      headerClasses: "text-center",
      text: intl.formatMessage({ id: "SELLERSTATUS" }),
      formatter: () => {
        return (
          <span
            // className={`label label-lg label-inline label-light-${
            //   data === true ? "success" : "danger"
            // }`}
            className={`label label-lg label-inline label-light-success`}
          >
            {/* {intl.formatMessage({id:data})} */}
            New
          </span>
        );
      },
    },
    {
      dataField: "Action",
      text: intl.formatMessage({ id: "Action" }),
      classes: "text-center",
      headerClasses: "text-center",
      formatter: () => (
        <>
          <ButtonGroup>
            <button
              className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
              onClick={(e) => {
                setDialog({
                  isOpen: true,
                  // ticketId: row.id,
                  // user_id: row.advertiser_id,
                });
              }}
            >
              {/* <a className="" href='#' onClick={() => uiProps.openUserProfile("23526")}> */}
              <span className="symbol-label">
                <SVG
                  className="svg-icon menu-icon"
                  style={{ fill: "blue", width: "17px" }}
                  src={toAbsoluteUrl("/media/svg/icons/Design/Edit.svg")}
                />
              </span>
              {/* </a> */}

              {/* <span>{data}</span> */}
            </button>
            <button
              className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
              onClick={(e) => {
                setDialog1({
                  isOpen: true,
                  // ticketId: row.id,
                  // user_id: row.advertiser_id,
                });
              }}
            >
              {/* <a className="" href='#' onClick={() => uiProps.openUserProfile("23526")}> */}
              <span className="symbol-label">
                <SVG
                  className="svg-icon menu-icon"
                  style={{ height: "18px" }}
                  src={toAbsoluteUrl("/media/svg/icons/Design/delete.svg")}
                />
              </span>
              {/* </a> */}

              {/* <span>{data}</span> */}
            </button>
          </ButtonGroup>
        </>
      ),
    },
  ];

  const columns = [
    {
      id: "col6",
      Header: intl.formatMessage({ id: "STATUS" }),
      accessor: (j) => j.shipmentStatus,
      sort: "asc",
      Cell: (cellContent) => {
        const Status_Buttons = (status) => {
          if (status === "ACCEPTED") {
            return (
              <Button
                size="sm"
                variant="warning"
                style={{ cursor: "pointer", width: "100px" }}
                // onClick={(e) => clickAccepted(e, cellContent.row.original._id)}
              >
                <strong>受注確認済み</strong>
              </Button>
            );
          }

          if (status === "RECEIVED") {
            return (
              <Button
                variant="danger"
                size="sm"
                style={{ cursor: "pointer", width: "100px" }}
                // onClick={(e) => clickReceived(e, cellContent.row.original._id)}
              >
                <strong>未処理</strong>
              </Button>
            );
          }

          if (status === "SHIPPED") {
            return (
              <Button
                variant="success"
                size="sm"
                style={{ cursor: "pointer", width: "100px" }}
                // onClick={clickShipped}
              >
                <strong>発送済み</strong>
              </Button>
            );
          }
          if (status === "FULFILLED") {
            return (
              <Button
                variant="secondary"
                size="sm"
                style={{ cursor: "pointer", width: "100px" }}
                disabled
              >
                <strong>発送確認済み</strong>
              </Button>
            );
          }
        };
        return Status_Buttons(cellContent.value);
      },
    },
    {
      id: "col25",
      Header: intl.formatMessage({ id: "SELLERNAME" }),
      accessor: (j) =>
        j.product?.seller_id
          ? j.product.seller_id.username.split(" ")[1]
            ? j.product.seller_id.username.split(" ")[1]
            : j.product.seller_id.username.split(" ")[0]
          : "削除された生産者",
    },
    {
      id: "col5",
      Header: intl.formatMessage({ id: "OrderedAt" }),
      accessor: (j) => j.orderedAt,
      Cell: (e) => moment(`${e.value}`).format("YYYY-MM-DD HH:mm"),
      //  e.value.split(" ")[0],
    },

    {
      id: "col1",
      Header: intl.formatMessage({ id: "ORDERID" }),
      accessor: (j) => j.order_id,
    },
    // {
    //   id: "col4",
    //   Header: intl.formatMessage({ id: "orderprice" }),
    //   accessor: (j) => j.baseOrderPrice,
    // },
    //order details
    {
      id: "col13234",
      Header: "注文詳細",
      accessor: (j) => {
        return (
          <Button
            variant="light"
            style={{ textAlign: "center" }}
            onClick={(e) => {
              // const my_discount_obj =
              //   j.sellerWiseDiscount.length !== 0
              //     ? j.sellerWiseDiscount.filter(
              //         (v) => v.seller === user.username
              //       )
              //     : [];
              setPriceShow(true);
              setPrice({
                order_id: j.order_id,
                subtotal: j.subtotal ? j.subtotal : 0,
                total_discount: j.total_discount ? j.total_discount : 0,
                total_shipping: j.total_shipping ? j.total_shipping : 0,
                total_tax: j.total_tax ? j.total_tax : 0,
                total_order_price: j.total_order_price
                  ? j.total_order_price
                  : 0,
                item_discount: j.item_discount ? j.item_discount : 0,
                item_price: j.item_price ? j.item_price : 0,
                baseOrderPrice: j.baseOrderPrice ? j.baseOrderPrice : 0,
                sellerWiseDiscount: j.sellerWiseDiscount,
              });
            }}
          >
            <EyeOutlined />
          </Button>
        );
      },
    },
    {
      id: "col2",
      Header: intl.formatMessage({ id: "orderquantity" }),
      accessor: (j) => j.orderQuantity,
    },
    {
      id: "col23",
      Header: intl.formatMessage({ id: "customername" }),
      accessor: (j) => j.customerName,
      Cell: (e) =>
        e.value.split(" ")[1]
          ? e.value.split(" ")[1] + " " + e.value.split(" ")[0]
          : "" + " " + e.value.split(" ")[0],
    },
    {
      id: "col3",
      Header: "購入者",
      accessor: (j) => {
        return (
          <Button
            variant="light"
            style={{ textAlign: "center" }}
            onClick={(e) => {
              setIsEditModal(true);
              setCustomer({
                customerName: j.customerName,
                customerPhone: j.phone,
                customerAddress:
                  j.pinCode +
                  ", " +
                  j.country +
                  ", " +
                  j.prefecture +
                  ", " +
                  j.city +
                  ", " +
                  j.address1 +
                  ", " +
                  j.address2,
                logisticsCompany: j.logisticsCompany,
                truckingNumber: j.truckingNumber,
              });
            }}
          >
            <EyeOutlined />
          </Button>
        );
      },
    },
    {
      id: "col22",
      Header: intl.formatMessage({ id: "PRODUCTNAME" }),
      accessor: (j) => j.product?.name,
    },

    {
      id: "col34",
      Header: "編集",
      accessor: (j) => {
        return (
          <div>
            {j.shipmentStatus === "FULFILLED" ? (
              <Button
                variant="light"
                style={{ textAlign: "center" }}
                onClick={(e) => {
                  e.preventDefault();
                  setId(j._id);
                  console.log(j._id);
                  setShippingModalShow(true);

                  // alert("ASKDASDKASD");
                  // setCustomer({
                  //   customerName: j.customerName,
                  //   customerAddress:
                  //     j.pinCode +
                  //     ", " +
                  //     j.country +
                  //     ", " +
                  //     j.prefecture +
                  //     ", " +
                  //     j.city +
                  //     ", " +
                  //     j.address1 +
                  //     ", " +
                  //     j.address2,
                  //   customerPhone: j.phone,
                  //   logisticsCompany: j.logisticsCompany,
                  //   truckingNumber: j.truckingNumber,
                  // });
                }}
              >
                <EyeOutlined />
              </Button>
            ) : (
              <Button
                variant="light"
                style={{ textAlign: "center" }}
                disabled
                title="Order is not shipped yet"
              >
                <EyeOutlined />
              </Button>
            )}
          </div>
        );
      },
    },

    // { id: "col3", Header: "顧客詳細", accessor: (j) => j.customerName },
  ];

  const columns23 = [
    {
      title: "Full Name",
      width: 100,
      dataIndex: "name",
      key: "name",
      fixed: "left",
    },
    {
      title: "Age",
      width: 100,
      dataIndex: "age",
      key: "age",
      fixed: "left",
    },
    { title: "Column 1", dataIndex: "address", key: "1" },
    { title: "Column 2", dataIndex: "address", key: "2" },
    { title: "Column 3", dataIndex: "address", key: "3" },
    { title: "Column 4", dataIndex: "address", key: "4" },
    { title: "Column 5", dataIndex: "address", key: "5" },
    { title: "Column 6", dataIndex: "address", key: "6" },
    { title: "Column 7", dataIndex: "address", key: "7" },
    { title: "Column 8", dataIndex: "address", key: "8" },
    {
      title: "Action",
      key: "operation",
      fixed: "right",
      width: 100,
      render: () => <a>action</a>,
    },
  ];

  const data23 = [
    {
      key: "1",
      name: "John Brown",
      age: 32,
      address: "New York Park",
    },
    {
      key: "2",
      name: "Jim Green",
      age: 40,
      address: "London Park",
    },
  ];

  return (
    <>
      <PriceModal
        show={priceShow}
        onHide={() => setPriceShow(false)}
        price={price}
      />
      <OrderDialog
        show={isEditModal}
        onHide={() => setIsEditModal(false)}
        customer={customer}
        // isEditModal={isEditModal}
        // handleCancel={handleCancel}
        // state={state}
      />

      {ShippingModalShow && (
        <EditShippedModal
          showModal={ShippingModalShow}
          hideModal={closeEditShippingModal}
          onSubmit={SubmitStatus2}
          loading={loader}
          orderId={Id}
        />
      )}

      <Card style={{ width: "1195px", display: "center" }}>
        <CardHeader
          title={intl.formatMessage({ id: "orderslist" })}
        ></CardHeader>
        <CardBody>
          {/* <Scrollbars  style={{width:"100%}}> */}
          <OrdersTable
            scrollY
            columns={columns}
            data={data}
            style={{
              width: "40vw",
            }}
          />
          {/* </Scrollbars> */}
        </CardBody>
      </Card>
    </>
  );
}
