import { request, getrequest } from "./api";
import { handleRequestGet } from "./api";




export const getSellers = () =>
  getrequest({ url: "sellers", type: "GET" });
export const getOrders = () =>
  getrequest({ url: "admin/orders/", type: "GET" });

export const getProducts = () =>
  getrequest({ url: "admin/products/", type: "GET"});

export const getShippedOrders = () =>
  getrequest({ url: "admin/orders/?shipmentStatus=SHIPPED", type: "GET" });






