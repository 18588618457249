import React, { useState, useEffect, useQuery } from "react";
import { Modal } from "react-bootstrap";
import { useIntl } from "react-intl";

export function StockUpdateDialog({ show, onHide, state, updateProduct }) {
  const [ownState, setOwnState] = useState([]);

  const updateFieldChanged = (index) => (e) => {
    console.log("index: " + index);
    console.log("property name: " + e.target.name);
    let newArr = [...ownState]; // copying the old datas array
    newArr[index].available = Number(e.target.value); // replace e.target.value with whatever you want to change it to

    setOwnState(newArr); // ??
  };

  useEffect(() => {
    setOwnState(state);
  }, [state]);

  const intl = useIntl();

  return (
    // <Modal
    //   centered
    //   show={show}
    //   onHide={onHide}
    //   scrollable={true}
    //   aria-labelledby="example-modal-sizes-title-lg"
     
    // >
    //   <Modal.Header closeButton>
    //     <Modal.Title id="example-modal-sizes-title-lg">
    //       {" "}
    //       {intl.formatMessage({ id: "productdetails" })}
    //     </Modal.Title>
    //   </Modal.Header>
    //   <Modal.Body>
        
    //   </Modal.Body>
    // </Modal>



<div>
<Modal show={show}
      onHide={onHide}
      scrollable={true}
     aria-labelledby="contained-modal-title-vcenter">
  <Modal.Header closeButton>
    <Modal.Title id="contained-modal-title-vcenter">
    {intl.formatMessage({ id: "productdetails" })}
    </Modal.Title>
  </Modal.Header>
  <Modal.Body className="show-grid">
  <form onSubmit={() => updateProduct()}>

          
<div className=" row">
  <label className="col-xl-4 col-lg-4 col-form-label">
    <h5>{intl.formatMessage({ id: "locationname" })}</h5>
  </label>

  <label className="col-xl-6 col-lg-6 col-form-label">
    <h5>{intl.formatMessage({ id: "availableproducts" })}</h5>
  </label>
</div>


{(ownState || []).map((item, index) => (
<React.Fragment>
  <div className=" row">
    <label className="col-xl-3 col-lg-3 col-form-label">
      {" "}
      {item.location_name}
    </label>
    <div className="col-lg-9 col-xl-6">
      <div>
        <input
          type="number"
          className={`form-control form-control-lg form-control-solid `}
          name="available"
          value={item.available}
          onChange={updateFieldChanged(index)}
        />
        <br />
      </div>
    </div>
  </div>
</React.Fragment>
))}

<div className="row">
  <button
    type="button"
    onClick={onHide}
    className="btn btn-light btn-elevate"
  >
    {intl.formatMessage({ id: "cancel" })}
  </button>

  <button
    type="button"
    className="btn btn-delete btn-primary"
    disabled={!ownState}
    style={{ marginLeft: "5px" }}
    onClick={(e) => {
      e.preventDefault();
      console.log("ssss");
      updateProduct({ data: ownState });
    }}
  >
    {intl.formatMessage({ id: "update" })}
  </button>
</div>
</form>
  </Modal.Body>
  
</Modal>
</div>
  );
}