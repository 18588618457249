import React, {useMemo} from "react";
import {Link} from "react-router-dom";
import objectPath from "object-path";
import SVG from "react-inlinesvg";
import {toAbsoluteUrl} from "../../../_helpers";
import {useHtmlClassService} from "../../_core/MetronicLayout";

// import { OverlayTrigger, Tooltip } from "react-bootstrap";


export function HeaderMobile() {
  const uiService = useHtmlClassService();

  const layoutProps = useMemo(() => {
    return {
      headerLogo: uiService.getStickyLogo(),
      asideDisplay: objectPath.get(uiService.config, "aside.self.display"),
      headerMenuSelfDisplay:
          objectPath.get(uiService.config, "header.menu.self.display") === true,
      headerMobileCssClasses: uiService.getClasses("header_mobile", true),
      headerMobileAttributes: uiService.getAttributes("header_mobile")
    };
  }, [uiService]);

  return (
      <>
        {/*begin::Header Mobile*/}
        <div
            id="kt_header_mobile"
            className={`header-mobile align-items-center ${layoutProps.headerMobileCssClasses}`}
            {...layoutProps.headerMobileAttributes}
        >
          {/*begin::Logo*/}
          {/* <Link to="/">
            <img alt="logo" src={layoutProps.headerLogo}/>
          </Link> */}
          <Link to="/" className="text-white">
          {/* <img alt="logo" src={layoutProps.headerLogo}/> */}
          <span
            style={{
              fontWeight: "bold",
              fontSize: "1.5rem",
              letterSpacing: "0.3rem",
            }}
          >
            たのしいたけ園
          </span>
        </Link>
          {/*end::Logo*/}

          {/*begin::Toolbar*/}
          <div className="d-flex align-items-center">
            {layoutProps.asideDisplay && (
                <>
                  {/*begin::Aside Mobile Toggle*/}
                  <span id="kt_aside_mobile_toggle" style={{marginRight:"2px",fontSize: "1.5rem",color:"#E2E3E8"}}  
             > メニュー   
                  <button className="btn p-0 burger-icon burger-icon-left" >
                    <span/>
                  </button>
             
                  
                  </span>
                  {/*end::Aside Mobile Toggle*/}
                </>
            )}

            {layoutProps.headerMenuSelfDisplay && (
                <>
                  {/*begin::Header Menu Mobile Toggle*/}
                  <button className="btn p-0 burger-icon ml-4" id="kt_header_mobile_toggle">
                    <span/>
                  </button>
                  {/*end::Header Menu Mobile Toggle*/}
                </>
            )}

            {/*begin::Topbar Mobile Toggle*/}
           
         
      
            {/* <button
                className="btn btn-hover-text-primary p-0 ml-2"
                id="kt_header_mobile_topbar_toggle"
            >
              <span className="svg-icon svg-icon-xl"> */}
                {/* <SVG src={toAbsoluteUrl("/media/svg/icons/General/User.svg")} /> */}
                {/* <img src={toAbsoluteUrl("/media/svg/icons/General/logout1.png")} />
              </span>
            </button> */}
             {/* <Link
              to="/logout"
              className="btn-hover-text-primary"
              
            >

            <button className="btn btn-hover-text-primary p-0 ml-2"
                >
                   <img src={toAbsoluteUrl("/media/svg/icons/General/logout1.png")} />
                    <span/>
                  </button>
                 <span id="kt_aside_mobile_toggle" style={{marginRight:"2px",fontSize: "1.1rem",}}  
             > ログアウト  
                  
                  </span>
                  </Link> */}
          
            {/*end::Topbar Mobile Toggle*/}
          </div>
          {/*end::Toolbar*/}
        </div>
        {/*end::Header Mobile*/}
      </>
  );
}
