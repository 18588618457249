import React, { Suspense, lazy, useEffect } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "../_metronic/layout";
import { BuilderPage } from "./pages/BuilderPage";

import CreateSeller  from "./pages/Seller/CreateSeller";
import { DashboardPage } from "./pages/DashboardPage";
import SellerCard from "./pages/Seller/SellerCard";
import OrderCard from "./pages/order/OrderCard";
import ProductCard from "./pages/products/ProductCard";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "./modules/Auth/_redux/authRedux";
import { parseJwt } from "./components/UIhelpers";
import {
  differenceInSeconds,
  differenceInMinutes,
  fromUnixTime,
  isFuture,
  differenceInMilliseconds,
} from "date-fns";
import { isPast } from "date-fns/esm";

// const GoogleMaterialPage = lazy(() =>
//   import("./modules/GoogleMaterialExamples/GoogleMaterialPage")
// );
// const ReactBootstrapPage = lazy(() =>
//   import("./modules/ReactBootstrapExamples/ReactBootstrapPage")
// );
// const ECommercePage = lazy(() =>
//   import("./modules/ECommerce/pages/eCommercePage")
// );
// const UserProfilepage = lazy(() =>
//   import("./modules/UserProfile/UserProfilePage")
// );

export default function BasePage() {
  const dispatch = useDispatch();
  const { authToken } = useSelector((state) => state.auth);
  const { exp } = parseJwt(authToken);

  useEffect(() => {
    const expiryTime = differenceInMilliseconds(fromUnixTime(exp), new Date());
    if (isPast(fromUnixTime(exp))) {
      dispatch(actions.logout());
    } else
      setTimeout(() => {
        dispatch(actions.logout());
      }, expiryTime);

    console.log(expiryTime);
  }, [exp]);

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {
          /* Redirect from root URL to /dashboard. */
          <Redirect exact from="/" to="/dashboard" />
        }
        {/* <ContentRoute path="/dashboard" component={DashboardPage} /> */}
        {/* <ContentRoute path="/builder" component={BuilderPage} /> */}
        <ContentRoute path="/dashboard" component={DashboardPage} />
        {/* <ContentRoute path="/settings" component={MyPage} /> */}
        <ContentRoute path="/sellers" component={SellerCard} />
        <ContentRoute path="/orders" component={OrderCard} />
        <ContentRoute path="/products" component={ProductCard} />
        <ContentRoute path="/create" component={CreateSeller} />

        {/* <Route path="/google-material" component={GoogleMaterialPage} />
        <Route path="/react-bootstrap" component={ReactBootstrapPage} />
        <Route path="/e-commerce" component={ECommercePage} />
        <Route path="/user-profile" component={UserProfilepage} /> */}
        <Redirect to="error/error-v1" />
      </Switch>
    </Suspense>
  );
}
