import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import SVG from "react-inlinesvg";
import React from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../_metronic/_partials/controls";
import { sortCaret, toAbsoluteUrl } from "../../../_metronic/_helpers"
import { ButtonGroup, Button } from "react-bootstrap";
import SellersTable from '../../components/SellersTable';
import { BrowserRouter, Route, Link } from "react-router-dom";
import { SellerUpdateDialog } from "../../sellerupdate/SellerUpdateDialog";
import { DeleteConfirmationDialog } from "../../DeleteConfirmationDialog/DeleteConfirmationDialog";
import axios from "axios";
import moment from "moment";
import { REACT_APP_API } from "../../../config";
import {
  retrieveProject,
  retrieveProduct,
  updateSellerAPI,
  deleteSellerAPI
} from "../../network/api";
import { useSnackbar } from "notistack";

import { EditOutlined, DeleteOutlined, PlusOutlined } from "@ant-design/icons";

import copy from "copy-to-clipboard";
import { useHistory } from "react-router-dom";


export default function SellerCard() {
  const history = useHistory();
  const intl = useIntl();
  const [data, setData] = useState([]);
  const [qty, setQty] = useState({ count: "" })
  const [locap, setLocap] = useState({
    prodloc: []
  });
  const [showConfirm, setShowConfirm] = useState(false);
  const handleRemoveClick = (i) => {
    const list = [...data];
    list.splice(i, 2);
    setData(list);
    setShowConfirm(false);
  };

  const copyToClipboard = (e) => {

    copy(state.sellerPhoneNumber);
    enqueueSnackbar(`コピーされました`, { variant: "info" });
    //  alert(`Seller phone number copied "${state.sellerPhoneNumber}"`);
  }
  const copyToClipboard1 = (e) => {
    copy(e);
    enqueueSnackbar(`コピーされました`, { variant: "info" });
    //  alert(`Seller phone number copied "${state.sellerPhoneNumber}"`);
  }
  const backToSellersList = () => {
    history.push("/sellers");
  };

  const deleteTask = (id) => {
    deleteSellerAPI(id).then((res) => {
      if (res.status === 204) {
        enqueueSnackbar(` 生産者は削除されました`, { variant: "info" });
        // backToSellersList();
        window.location.reload();
      }
    });
  };
  const confirm = (id) => {
    deleteTask(id);
  };


  // [ 'hnwulsekqn', 'qlioullgew', 'kosxwabgjv' ]

  const [state, setState] = useState({

    username: "",
    email: "",
    sellerPhoneNumber: null,
    sellerKatakana:"",
    gender: "",
    dob: "",
    sellerPostalCode: "",
    sellerAddress: "",
    disabilityCategory: "",
    disabilityCertificate: "",
    guardianName: "",
    guardianGender: "",
    relationToSeller: "",
    livingStatus: "",
    guardianPostalCode: "",
    guardianAddress: "",
    guardianKatakana:"",
    guardianPhoneNumber: "",
    guardianEmailAddress: "",
    remarks: "",
    createdAt: ""

  });
  const [isEditModal, setIsEditModal] = useState(false);
  const [isEditModal1, setIsEditModal1] = useState(false);

  const [ID, setID] = useState("");
  const [ID1, setID1] = useState("");
  const { enqueueSnackbar } = useSnackbar();

  const fetchData = () =>
    axios.get(`${process.env.REACT_APP_API}sellers`);
  const ff = async () => {
    var dd = await fetchData();
    console.log("dd sellers data", dd.data.data);
    setData(dd.data.data);
  };
  useEffect(() => {
    ff();
  }, []);


  const getProduct = (id) => {
    retrieveProduct(id).then((res) => {
      setState({

        username: res.data.data.username,
        email: res.data.data.email,
        sellerKatakana:res.data.data.sellerKatakana,
        sellerPhoneNumber: res.data.data.sellerPhoneNumber,
        gender: res.data.data.gender,
        dob: res.data.data.dob,
        sellerPostalCode: res.data.data.sellerPostalCode,
        sellerAddress: res.data.data.sellerAddress,
        disabilityCategory: res.data.data.disabilityCategory,
        disabilityCertificate: res.data.data.disabilityCertificate,
        guardianName: res.data.data.guardianName,
        guardianGender: res.data.data.guardianGender,
        relationToSeller: res.data.data.relationToSeller,
        livingStatus: res.data.data.livingStatus,
        guardianPostalCode: res.data.data.guardianPostalCode,
        guardianAddress: res.data.data.guardianAddress,
        guardianPhoneNumber: res.data.data.guardianPhoneNumber,
        guardianKatakana:res.data.data.guardianKatakana,
        guardianEmailAddress: res.data.data.guardianEmailAddress,
        remarks: res.data.data.remarks,
        createdAt: res.data.data.createdAt
      });
      setID(id);
      console.log("IDP", res.data);
      setIsEditModal(true);
      console.log("rep phone number", res.data.sellerPhoneNumber);
    });
  };

  const getProduct1 = (id) => {

    setID1(id);
    setIsEditModal1(true);


  };

  const updateSeller = async (obj) => {

    console.log("obj", obj);
    await axios.put(`${REACT_APP_API}sellers/${ID}`, obj);
    ff();
    enqueueSnackbar("商品が作成されました。", { variant: "success" });
    setIsEditModal(false);
  };

  const handleCancel = () => {

    setIsEditModal(false);
    setState({
      username: "",
      email: "",
      sellerPhoneNumber: "",
      sellerKatakana: "",
      gender: "",
      dob: "",
      sellerPostalCode: "",
      sellerAddress: "",
      disabilityCategory: "",
      disabilityCertificate: "",
      guardianName: "",
      guardianGender: "",
      relationToSeller: "",
      livingStatus: "",
      guardianPostalCode: "",
      guardianKatakana: "",
      guardianAddress: "",
      guardianPhoneNumber: "",
      guardianEmailAddress: "",
      remarks: "",
      createdAt: ""

    });
  };
  const updateTask = async () => {

    let payload = {
      ...state,
      username: state.username,
      email: state.email,
      sellerKatakana:state.sellerKatakana,
      guardianKatakana:state.guardianKatakana,
      sellerPhoneNumber: state.sellerPhoneNumber,
      gender: state.gender,
      dob: state.dob,
      sellerPostalCode: state.sellerPostalCode,
      sellerAddress: state.sellerAddress,
      disabilityCategory: state.disabilityCategory,
      disabilityCertificate: state.disabilityCertificate,
      guardianName: state.guardianName,
      guardianGender: state.guardianGender,
      relationToSeller: state.relationToSeller,
      livingStatus: state.livingStatus,
      guardianPostalCode: state.guardianPostalCode,
      guardianAddress: state.guardianAddress,
      guardianPhoneNumber: state.guardianPhoneNumber,
      guardianEmailAddress: state.guardianEmailAddress,
      remarks: state.remarks,
      createdAt: state.createdAt
    };
    console.log("payyyload", payload)
    await axios.put(`${REACT_APP_API}sellers/${ID}`, payload).then((res) => {
      if (res && res.status === 200) {
        setIsEditModal(false);
        ff();
        setState({
          username: "",
          email: "",
          sellerPhoneNumber: "",
          sellerKatakana: "",
          gender: "",
          dob: "",
          sellerPostalCode: "",
          sellerAddress: "",
          disabilityCategory: "",
          disabilityCertificate: "",
          guardianName: "",
          guardianGender: "",
          relationToSeller: "",
          guardianKatakana: "",
          livingStatus: "",
          guardianPostalCode: "",
          guardianAddress: "",
          guardianPhoneNumber: "",
          guardianEmailAddress: "",
          remarks: "",
          createdAt: ""

        });
        enqueueSnackbar("生産者詳細が更新されました。", { variant: "success" });
      }
    });

  };

  const [dialog, setDialog] = useState({
    isOpen: false,
    // user_id: "",
    // ticketId: "",
    // id: "",
    // note: "",
    // image: "",
    // viewOnly: false,
  });

  const [dialog1, setDialog1] = useState({
    isOpen: false,
    // user_id: "",
    // ticketId: "",
    // id: "",
    // note: "",
    // image: "",
    // viewOnly: false,
  });


  var mydata =
    [
      {

        "SellerID": "ORD1",
        "SellerName": "Anjali",
        "Created At": "2021/06/06",
        "Action": "",
        "SellerStatus": "New",
      },
      {

        "SellerID": "PRD1",
        "SellerName": "Anjali",
        "Created At": "2021/04/06",
        "Action": "",
        "SellerStatus": "New",
      },
      {

        "SellerID": "QRD1",
        "SellerName": "Anjali",
        "Created At": "2021/06/06",
        "Action": "",
        "SellerStatus": "New",
      },
      {

        "SellerID": "WRD1",
        "SellerName": "Ginji",
        "Created At": "2021/07/06",
        "Action": "",
        "SellerStatus": "New",
      },
      {

        "SellerID": "DRD1",
        "SellerName": "Varun",
        "Created At": "2021/06/06",
        "Action": "",
        "SellerStatus": "New",
      },
      {

        "SellerID": "ORD1",
        "SellerName": "Biren",
        "Created At": "2021/08/06",
        "Action": "",
        "SellerStatus": "New",
      },


    ];

  const columnssss = React.useMemo(
    () => [

      {
        Header: '  ',
        columns: [

          {
            Header: 'SellerID',
            accessor: 'SellerID'
          },
          {
            Header: 'SellerName',
            accessor: 'SellerName',
          },

          {
            Header: 'Action',
            accessor: 'Action',
            Cell: () => (
              <>
                <ButtonGroup>
                  <button
                    className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
                    onClick={(e) => {

                      setDialog({
                        isOpen: true,
                        // ticketId: row.id,
                        // user_id: row.advertiser_id,
                      });
                    }}
                  >
                    {/* <a className="" href='#' onClick={() => uiProps.openUserProfile("23526")}> */}
                    <span className="symbol-label">
                      <SVG
                        className="svg-icon menu-icon"
                        style={{ fill: "blue", width: "17px" }}
                        src={toAbsoluteUrl(
                          "/media/svg/icons/Design/Edit.svg"
                        )}
                      />
                    </span>
                    {/* </a> */}

                    {/* <span>{data}</span> */}

                  </button>
                  <button
                    className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
                    onClick={(e) => {

                      setDialog1({
                        isOpen: true,
                        // ticketId: row.id,
                        // user_id: row.advertiser_id,
                      });
                    }}
                  >
                    {/* <a className="" href='#' onClick={() => uiProps.openUserProfile("23526")}> */}
                    <span className="symbol-label">
                      <SVG
                        className="svg-icon menu-icon"
                        style={{ height: "18px" }}
                        src={toAbsoluteUrl(
                          "/media/svg/icons/Design/delete.svg"
                        )}
                      />
                    </span>
                    {/* </a> */}

                    {/* <span>{data}</span> */}

                  </button>

                </ButtonGroup>
              </>
            ),
          },

        ],
      },
    ],
    []
  )

  const column = [
    {
      dataField: "SellerID",
      classes: "text-center",
      headerClasses: "text-center",
      text: intl.formatMessage({ id: "SELLERID" }),
      // formatter: (prop, row) => `${row.first_name} ${row.last_name}`,
    },
    {
      dataField: "name", classes: "text-center",
      headerClasses: "text-center", text: intl.formatMessage({ id: "SELLERNAME" })
    },
    {
      dataField: "Created At", classes: "text-center",
      headerClasses: "text-center", text: intl.formatMessage({ id: "CREATEDAT" })
    },
    {
      dataField: "SellerStatus",
      classes: "text-center",
      headerClasses: "text-center",
      text: intl.formatMessage({ id: "SELLERSTATUS" }),
      formatter: () => {
        return (
          <span
            // className={`label label-lg label-inline label-light-${
            //   data === true ? "success" : "danger"
            // }`}
            className={`label label-lg label-inline label-light-success`}
          >
            {/* {intl.formatMessage({id:data})} */}
            New
          </span>
        );
      },

    },
    {
      dataField: "Action",
      text: intl.formatMessage({ id: "Action" }),
      classes: "text-center",
      headerClasses: "text-center",
      formatter: () => (
        <>
          <ButtonGroup>
            <button
              className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
              onClick={(e) => {

                setDialog({
                  isOpen: true,
                  // ticketId: row.id,
                  // user_id: row.advertiser_id,
                });
              }}
            >
              {/* <a className="" href='#' onClick={() => uiProps.openUserProfile("23526")}> */}
              <span className="symbol-label">
                <SVG
                  className="svg-icon menu-icon"
                  style={{ fill: "blue", width: "17px" }}
                  src={toAbsoluteUrl(
                    "/media/svg/icons/Design/Edit.svg"
                  )}
                />
              </span>
              {/* </a> */}

              {/* <span>{data}</span> */}

            </button>
            <button
              className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
              onClick={(e) => {

                setDialog1({
                  isOpen: true,
                  // ticketId: row.id,
                  // user_id: row.advertiser_id,
                });
              }}
            >    <span className="symbol-label">
                <SVG
                  className="svg-icon menu-icon"
                  style={{ height: "18px" }}
                  src={toAbsoluteUrl(
                    "/media/svg/icons/Design/delete.svg"
                  )}
                />
              </span>
            </button>

          </ButtonGroup>
        </>
      ),
    },


  ];


  const columns = [
    // {
    //   id: "col1",
    //   label: "カテゴリー",
    //   field: "productId",
    //   accessor: (j) => j.product_id,
    //   sort: "asc",
    //   // width: 200
    // },
    {
      id: "col2",
      Header: intl.formatMessage({ id: "SELLERNAME" }),
      field: "username",
      accessor: (j) => j.username,
      sort: "asc",
      Cell: (e) => e.value.split(" ")[1] ?  e.value.split(" ")[1]:" " +"" +  e.value.split(" ")[0]
      // width: 200
    },
    {
      id: "col3",
      Header: intl.formatMessage({ id: "Selleremail" }),
      field: "email",
      sort: "asc",
      accessor: (j) => j.email,
    
    },
    
    {
      id: "col4",
      Header: intl.formatMessage({ id: "Sellerphoneno" }),
      field: "sellerPhoneNumber",
      sort: "asc",
      accessor: (j) => j.sellerPhoneNumber,
      // width: 200
      Cell: (e) => (
        <label className="col col-form-label">
          <span>{e.value}</span>
          {e.value && <i class="fa fa-clone" onClick={() => copyToClipboard1(e.value)}></i>}
        </label>
      )
    },
    {
      id: "col21",
      Header: intl.formatMessage({ id: "Nameofguardian" }),
      field: "guardianName",
      accessor: (j) => j.guardianName,
      sort: "asc",
      // Cell: (e) => e.value.split("   ")[1] ?  e.value.split("   ")[1]:" " +"" +  e.value.split(" ")[0]
      // width: 200
    },
    {
      id: "col23",
      Header: intl.formatMessage({ id: "Parentemail" }),
      field: "guardianEmailAddress",
      accessor: (j) => j.guardianEmailAddress,
      sort: "asc",
      style: { overflowWrap: "break-word" },


      // width: 200
    },
    {
      id: "col22",
      Header: intl.formatMessage({ id: "Parentphone" }),
      field: "guardianPhoneNumber",
      accessor: (j) => j.guardianPhoneNumber,
      sort: "asc",
      Cell: (e) => (
        <label className="col col-form-label">
          <span>{e.value}</span>
          {e.value && <i class="fa fa-clone" onClick={() => copyToClipboard1(e.value)}></i>}
        </label>
      )
      // width: 200
    },

    {
      id: "col5",
      Header: intl.formatMessage({ id: "CREATEDAT" }),
      field: "createdAt",
      accessor: (j) => j.createdAt,
      sort: "asc",
      Cell: (e) => moment(`${e.value}`).format("YYYY-MM-DD HH:mm"),
      // width: 200
    },

    {
      id: "col6",
      Header: intl.formatMessage({ id: "Details" }),
      field: "_id",
      accessor: (j) => j._id,
      sort: "asc",
      Cell: (e) => (
        <div className="row">
          <EditOutlined
          style={{marginTop:"4px"}}
          className="btn-hover-primary mx-3"
                        key="edit"
                        onClick={() => getProduct(`${e.value}`)}
                      />
         <DeleteOutlined
          style={{marginTop:"4px"}}
         className="btn-hover-primary mx-3"
                          key="ellipsis"
                          onClick={() => getProduct1(`${e.value}`)}
                        />
       
        </div>

      ),
      width: 200

    },

  ];


  return (
    <>
      <SellerUpdateDialog
        isEditModal={isEditModal}
        handleCancel={handleCancel}
        state={state}
        setState={setState}
        updateTask={updateTask}


      />
      <DeleteConfirmationDialog
        confirm={confirm}
        ID1={ID1}
        show={isEditModal1}
        onHide={() => setIsEditModal1(false)}
      />
      <Card style={{width: "1195px", display:"center",}}>
        <CardHeader title={intl.formatMessage({ id: "SELLERSLIST" })}>
          <CardHeaderToolbar>
            <Link to="/create" className="btn btn-primary">{intl.formatMessage({ id: "ADDNEWSELLER" })}</Link>
          </CardHeaderToolbar>
        </CardHeader>
        <CardBody>

          <SellersTable columns={columns} data={data} style={{overflow:'wrap'}}/>

        </CardBody>
      </Card>
    </>
  );
}



