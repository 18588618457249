import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useQuery } from "react-query";
import { query } from "../../network";
import SVG from "react-inlinesvg";
import axios from "axios";
import React from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../_metronic/_partials/controls";
import { sortCaret, toAbsoluteUrl } from "../../../_metronic/_helpers"
import { selectFilter, textFilter } from "react-bootstrap-table2-filter";
import { ButtonGroup, Button } from "react-bootstrap";
import ProductsTable from '../../components/ProductsTable';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { formatDateTime } from "../../components/UIhelpers";

import {
  retrieveProject,
  retrieveProduct,
  updateProductAPI,
} from "../../network/api";


import moment from "moment";
import { useSnackbar } from "notistack";
import { REACT_APP_API } from "../../../config";
import { StockUpdateDialog } from "../../stockupdate/StockUpdateDialog";






export default function ProductCard() {
  const intl = useIntl();
  const [data1, setData1] = useState([]);
  const [tableData, setTableData] = useState({ data: [] })
  const [productData, setProductData] = useState({ prodsData: [] })


  const [state, setState] = useState({
    username: "",
  });
  // const [isEditModal, setIsEditModal] = useState(false);
  // const [ID, setID] = useState("");
  // const [data, setData] = useState([]);
  const [locap, setLocap] = useState({
    prodloc: [],
  });
  // const [InventoryData, setInventoryData] = useState([]);
  // const { data, isLoading, isError } = useQuery("products", query.getProducts);
  // useEffect(() => {
  //   setData1(data?.data?.data)
  //   console.log("dataaaaaaaaaaa", data?.data?.data);
  // }, [data])


  const getProject = (id) => {
    retrieveProject(id).then((res) => {
      setState({
        username: res.data.data.username,
      });
      setID(id);
      console.log("ID", ID);
      setIsEditModal(true);
      console.log("resssssssssssss", res.data.data.username)

    });
  };
  const { SearchBar } = Search;
  const [dialog, setDialog] = useState({
    isOpen: false,
    // user_id: "",
    // ticketId: "",
    // id: "",
    // note: "",
    // image: "",
    // viewOnly: false,
  });

  const [dialog1, setDialog1] = useState({
    isOpen: false,
    // user_id: "",
    // ticketId: "",
    // id: "",
    // note: "",
    // image: "",
    // viewOnly: false,
  });



  const { enqueueSnackbar } = useSnackbar();

  // const getProduct = (id) => {
  //   retrieveProduct(id).then((res) => {
  //     setLocap({
  //       ...locap,
  //       prodloc: res.data,
  //     });
  //     setID(id);
  //     console.log("IDP", ID);
  //     setIsEditModal(true);
  //     console.log("rep", res.data.inventory_levels);
  //   });
  // };

  // const fetchData = () =>
  //   axios.get(`${process.env.REACT_APP_API}admin/products/`);
  // const ff = async () => {
  //   var dd = await fetchData();
  //   console.log("dd data", dd.data.data);
  //   setData(dd.data.data);
  // };
  // useEffect(() => {
  //   ff();
  // }, []);


  // const fetchInventory = (id) => axios.get(`${REACT_APP_API}products/${id}`);
  // const fetchLocations = () => axios.get(`${REACT_APP_API}locations`);
  // const getInventory = async (id) => {
  //   var dd = await fetchInventory(id);
  //   console.log(dd.data.inventory_levels);
  //   // setInventoryData(dd.data.inventory_levels);
  //   var { data: locations } = await fetchLocations();
  //   setIsEditModal(true);
  //   const array = dd.data.inventory_levels.map((obj) => {
  //     for (let i = 0; i < locations.locations.length; i++) {
  //       if (locations.locations[i].id === obj.location_id) {
  //         return { ...obj, ...{ location_name: locations.locations[i].name } };
  //       }
  //     }
  //   });
  //   setInventoryData(array);
  //   setID(id);

  // };

  // const updateProduct = async (obj) => {
  //   console.log(obj);
  //   await axios.post(`${REACT_APP_API}products/${ID}`, obj);
  //   ff();
  //   enqueueSnackbar("商品が作成されました。", { variant: "success" });
  //   setIsEditModal(false);
  // };







  
  const [data, setData] = useState([]);
  const [success, setSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [isEditModal, setIsEditModal] = useState(false);
  const [ID, setID] = useState("");
  const [InventoryData, setInventoryData] = useState([]);

  const fetchData = () => axios.get(`${REACT_APP_API}admin/products/`);

  const fetchInventory = (id) => axios.get(`${REACT_APP_API}products/${id}`);
  const fetchLocations = () => axios.get(`${REACT_APP_API}locations`);

  const getInventory = async (id) => {
    var dd = await fetchInventory(id);
    console.log(dd.data.inventory_levels);
    // setInventoryData(dd.data.inventory_levels);
    var { data: locations } = await fetchLocations();

    const array = dd.data.inventory_levels.map((obj) => {
      for (let i = 0; i < locations.locations.length; i++) {
        if (locations.locations[i].id === obj.location_id) {
          return { ...obj, ...{ location_name: locations.locations[i].name } };
        }
      }
    });
    setInventoryData(array);
    setID(id);
    setIsEditModal(true);
  };

  const onClose = () => {
    console.log("clicked");
    setSuccess(false);
  };

  const updateProduct = async (obj) => {
    console.log(obj);

    var res = await axios.post(
      `${process.env.REACT_APP_API}products/${ID}`,
      obj
    );

    ff();
    enqueueSnackbar("商品が作成されました。", { variant: "success" });
    setIsEditModal(false);
      
  };

  const ff = async () => {
    var dd = await fetchData();
    console.log(dd.data.data);
    setData(dd.data.data);
  };

  useEffect(() => {
    ff();
  }, []);


  var mydata =
    [
      {

        "Category": "Category A",
        "Product name": "test product",
        "Price": "500",
        "Qty": "23",
        "Seller": "Anjali",
        "Status": "",
        "Product Added": "2021/06/06",
      },
      {

        "Category": "Category A",
        "Product name": "test product",
        "Price": "500",
        "Qty": "21",
        "Seller": "Anjali",
        "Status": "",
        "Product Added": "2021/06/06",
      },
      {

        "Category": "Category B",
        "Product name": "XYZ product",
        "Price": "600",
        "Qty": "27",
        "Seller": "Anjali",
        "Status": "",
        "Product Added": "2021/06/06",
      },
      {

        "Category": "Category C",
        "Product name": "ABC product",
        "Price": "800",
        "Qty": "23",
        "Seller": "Anjali",
        "Status": "",
        "Product Added": "2021/04/06",
      },
      {

        "Category": "Category D",
        "Product name": "NY product",
        "Price": "500",
        "Qty": "23",
        "Seller": "Anjali",
        "Status": "",
        "Product Added": "2021/05/06",
      },
      {

        "Category": "Category A",
        "Product name": "test product",
        "Price": "500",
        "Qty": "23",
        "Seller": "Anjali",
        "Status": "",
        "Product Added": "2021/06/06",
      },
      {

        "Category": "Category A",
        "Product name": "test product",
        "Price": "500",
        "Qty": "23",
        "Seller": "Anjali",
        "Status": "",
        "Product Added": "2021/06/06",
      },



    ];

  const columns1 = [{
    dataField: 'name',
    text: intl.formatMessage({ id: "PRODUCTNAME" }),
    sort: true
  }, {
    dataField: 'price',
    text: intl.formatMessage({ id: "PRICE" }),
    sort: true
  },
  {
    dataField: 'stock_quantity',
    text: intl.formatMessage({ id: "QUANTITY" }),
    sort: true
  },
  // {
  //   dataField: 'seller_id',
  //   text: intl.formatMessage({ id: "SELLERNAME" }),
  //   sort: true,
  //   formatter: (seller_id) => {
  //     getProductData(seller_id)
  //     return (
  //       <span
  //         // className={`label label-lg label-inline label-light-${
  //         //   data === true ? "success" : "danger"
  //         // }`}
  //         className={`label label-lg label-inline label-light-success`}
  //       >
  //         {productData.prodsData.username}

  //       </span>
  //     );
  //   },



  // },
  {
    dataField: 'created_at',
    text: intl.formatMessage({ id: "PRODUCTADDED" }),
    sort: true,
    formatter: (data) =>
      data ? formatDateTime(data) : "not yet",
  },
  {
    dataField: "status",
    sort: true,
    text: intl.formatMessage({ id: "STATUS" }),
    formatter: (data) => {
      return (
        <span
          // className={`label label-lg label-inline label-light-${
          //   data === true ? "success" : "danger"
          // }`}
          className={`label label-lg label-inline label-light-success`}
        >
          {intl.formatMessage({ id: data })}

        </span>
      );
    },


  },
  {
    dataField: "seller_id",
    text: intl.formatMessage({ id: "ACTION" }),
    classes: "text-center",
    headerClasses: "text-center",
    formatter: (data, row) => {
      return (
        <button
          className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
          // onClick={fetchData(row._id)}
          onClick={() => getProject(data)}
        // onClick={(e) => {

        //   setDialog1({
        //     isOpen: true,
        //     // ticketId: row.id,
        //     user_id: row.seller_id,
        //   });
        // }}
        >
          {/* <a className="" href='#' onClick={() => uiProps.openUserProfile("23526")}> */}
          <span className="symbol-label">
            <SVG
              className="svg-icon menu-icon"
              style={{ fill: "blue", width: "17px" }}
              src={toAbsoluteUrl(
                "/media/svg/icons/Design/Edit.svg"
              )}
            />
          </span>
          {/* </a> */}

          <span>{data}</span>

        </button>
      );
    }
    // formatter: (data) => (
    //   <>
    //     <ButtonGroup>
    //       <button
    //         className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
    //         onClick={(e) => {

    //           setDialog({
    //             isOpen: true,
    //             // ticketId: row.id,
    //             // user_id: row.advertiser_id,
    //           });
    //         }}
    //       >
    //         {/* <a className="" href='#' onClick={() => uiProps.openUserProfile("23526")}> */}
    //         <span className="symbol-label">
    //           <SVG
    //             className="svg-icon menu-icon"
    //             style={{ fill: "blue", width: "17px" }}
    //             src={toAbsoluteUrl(
    //               "/media/svg/icons/Design/Edit.svg"
    //             )}
    //           />
    //         </span>
    //         {/* </a> */}

    //         {/* <span>{data}</span> */}

    //       </button>

    //     </ButtonGroup>
    //   </>
    // ),
  },
  ];

  const columnssss = React.useMemo(
    () => [

      {
        Header: '  ',
        columns: [

          //   {
          //     Header: 'Category',
          //     accessor: 'Category'
          // },
          {
            Header: 'Product name',
            accessor: 'name',
          },
          {
            Header: 'price',
            accessor: 'price'
          },
          {
            Header: 'Qty',
            accessor: 'stock_quantity'
          },
          {
            Header: 'Date',
            accessor: 'Product Added'
          },
          {
            Header: 'Seller',
            accessor: 'Seller'
          },
          {
            Header: 'Status',
            accessor: 'status',
            Cell: () => {
              return (
                <span
                  // className={`label label-lg label-inline label-light-${
                  //   data === true ? "success" : "danger"
                  // }`}
                  className={`label label-lg label-inline label-light-success`}
                >
                  {/* {intl.formatMessage({id:data})} */}
                  New
                </span>
              );
            },

          },
          {
            Header: 'Action',
            accessor: 'seller_id',
            Cell: () => (
              <>
                <ButtonGroup>
                  <button
                    className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
                    onClick={(e) => {

                      setDialog({
                        isOpen: true,
                        // ticketId: row.id,
                        // user_id: row.advertiser_id,
                      });
                    }}
                  >
                    {/* <a className="" href='#' onClick={() => uiProps.openUserProfile("23526")}> */}
                    <span className="symbol-label">
                      <SVG
                        className="svg-icon menu-icon"
                        style={{ fill: "blue", width: "17px" }}
                        src={toAbsoluteUrl(
                          "/media/svg/icons/Design/Edit.svg"
                        )}
                      />
                    </span>
                    {/* </a> */}

                    {/* <span>{data}</span> */}

                  </button>
                  <button
                    className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
                    onClick={(e) => {

                      setDialog1({
                        isOpen: true,
                        // ticketId: row.id,
                        // user_id: row.advertiser_id,
                      });
                    }}
                  >
                    {/* <a className="" href='#' onClick={() => uiProps.openUserProfile("23526")}> */}
                    <span className="symbol-label">
                      <SVG
                        className="svg-icon menu-icon"
                        style={{ height: "18px" }}
                        src={toAbsoluteUrl(
                          "/media/svg/icons/Design/delete.svg"
                        )}
                      />
                    </span>
                    {/* </a> */}

                    {/* <span>{data}</span> */}

                  </button>

                </ButtonGroup>
              </>
            ),
          },

        ],
      },
    ],
    []
  )

  const column = [
    {
      dataField: "Category", text: intl.formatMessage({ id: "Category" }), sortCaret: sortCaret
    },

    {
      dataField: "name",
      text: intl.formatMessage({ id: "Product name" }),
      sortCaret: sortCaret,

    },
    {
      dataField: "price", text: intl.formatMessage({ id: "Price" }),
    },
    {
      dataField: "Qty",
      text: intl.formatMessage({ id: "Qty" }),
    },
    { dataField: "Product Added", text: intl.formatMessage({ id: "Date" }) },
    {
      dataField: "Seller", text: intl.formatMessage({ id: "Seller" }), sortCaret: sortCaret,
      filter: textFilter({
        // defaultValue: pagination.filters?.email,
        className: "col-field",
        placeholder: intl.formatMessage({ id: "Search" }),
      }),
    },
    {
      dataField: "status",
      classes: "text-center",
      headerClasses: "text-left",
      text: intl.formatMessage({ id: "Status" }),
      formatter: (data) => {
        return (
          <span
            // className={`label label-lg label-inline label-light-${
            //   data === true ? "success" : "danger"
            // }`}
            className={`label label-lg label-inline label-light-success`}
          >
            {intl.formatMessage({ id: data })}

          </span>
        );
      },

    },
    {
      dataField: "Action",
      text: intl.formatMessage({ id: "Action" }),
      classes: "text-center",
      headerClasses: "text-center",
      formatter: () => (
        <>
          <ButtonGroup>
            <button
              className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
              onClick={(e) => {

                setDialog({
                  isOpen: true,
                  // ticketId: row.id,
                  // user_id: row.advertiser_id,
                });
              }}
            >
              {/* <a className="" href='#' onClick={() => uiProps.openUserProfile("23526")}> */}
              <span className="symbol-label">
                <SVG
                  className="svg-icon menu-icon"
                  style={{ fill: "blue", width: "17px" }}
                  src={toAbsoluteUrl(
                    "/media/svg/icons/Design/Edit.svg"
                  )}
                />
              </span>
              {/* </a> */}

              {/* <span>{data}</span> */}

            </button>
            <button
              className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
              onClick={(e) => {

                setDialog1({
                  isOpen: true,
                  // ticketId: row.id,
                  // user_id: row.advertiser_id,
                });
              }}
            >
              {/* <a className="" href='#' onClick={() => uiProps.openUserProfile("23526")}> */}
              {/* <span className="symbol-label">
              <SVG
                className="svg-icon menu-icon"
                style={{height:"18px"}}
                src={toAbsoluteUrl(
                  "/media/svg/icons/Design/delete.svg"
                )}
              />
            </span> */}
              {/* </a> */}

              {/* <span>{data}</span> */}

            </button>

          </ButtonGroup>
        </>
      ),
    },
    // {
    //   dataField: "establised_year",
    //   text: intl.formatMessage({ id: "establised_year" }),
    // },
  ];

  const columns = [
    // {
    //   id: "col1",
    //   label: "カテゴリー",
    //   field: "productId",
    //   accessor: (j) => j.product_id,
    //   sort: "asc",
    //   // width: 200
    // },
    {
      id: "col2",
      Header: intl.formatMessage({ id: "PRODUCTNAME" }),
      field: "product_name",
      accessor: (j) => j.name,
      sort: "asc",
      // width: 200
    },
    {
      id: "col3",
      Header: intl.formatMessage({ id: "PRICE" }),
      field: "price",
      sort: "asc",
      accessor: (j) => j.price,
      // width: 200
    },
    {
      id: "col4",
      Header:  intl.formatMessage({ id: "QUANTITY" }),
      field: "qty",
      sort: "asc",
      accessor: (j) => j.stock_quantity,
      // width: 200
    },
    {
      id: "col5",
      Header:intl.formatMessage({ id: "PRODUCTADDED" }),
      field: "date",
      accessor: (j) => j.createdAt,
      sort: "asc",
      Cell: (e) =>  moment(`${e.value}`).format("YYYY-MM-DD HH:mm"),
      // width: 200
    },
    {
      id: "col21",
      Header: intl.formatMessage({ id: "SELLERNAME" }),
      field: "seller_id",
      accessor: (j) => j.seller_id ? j.seller_id.username:"削除された生産者",
      sort: "asc",
      // width: 200
    },
    {
      id: "col6",
      Header: "アクション",
      field: "_id",
      accessor: (j) => j._id,
      sort: "asc",
      Cell: (e) => (
        <button
          className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
          onClick={() => getInventory(`${e.value}`)}
        >
          <span className="symbol-label">
            <SVG
              className="svg-icon menu-icon"
              style={{ fill: "blue", width: "17px" }}
              src={toAbsoluteUrl("/media/svg/icons/Design/Edit.svg")}
            />
          </span>
        </button>
      ),
      // width: 200
    },
  ];
  return (
    <>
      <StockUpdateDialog
        state={InventoryData}
        // state={locap.prodloc}
        updateProduct={updateProduct}
        id={ID}
        setState={setLocap}
        show={isEditModal}
        onHide={() => setIsEditModal(false)}
      />
      <Card  style={{width: "1195px", display:"center",}}>
        <CardHeader title={intl.formatMessage({ id: "PRODUCTSLIST" })}>
          <CardHeaderToolbar>          
          </CardHeaderToolbar>
        </CardHeader>
        <CardBody>        
          <ProductsTable columns={columns} data={data} />      
        </CardBody>
      </Card>
    </>
  );
}
